import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import { useTheme, InputAdornment, Tooltip, Button, Typography, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import React, { ChangeEvent, FC, KeyboardEvent, useState, useEffect, useRef } from "react";
import AppStatus from "../../../model/conversation/statusMessages";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { ChatInputField, QuestionItemContainer, SuggestedQuestionsContainer } from "../shared";
import fetchSampleQuestions, { FetchQuestionResult } from "../../../api/chat/fetchQuestions";
import { SearchDivider } from "../shared";
import { Spacer } from "../../fre/shared";
import { useAppContext } from "../../app/appContext";
interface ChatInputProps {
  sendChat: (text: string) => void;
}

/**
 * Renders a chat input component.
 * @param {Object} props - The component props.
 * @param {Function} props.sendChat - The function to send a chat message.
 * @returns {JSX.Element} - The rendered component.
 */
const ChatInput: FC<ChatInputProps> = ({ sendChat}) => {
  const [message, setMessage] = useState("");
  const [suggestedQuestions, setSuggestedQuestions] = useState<string[]>([])
  const [showQuestions, setShowQuestions] = useState<boolean>(false)
  const inputRef = useRef<HTMLDivElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const {appStatus} = useAppContext();

  const handleClickOutside = (event: MouseEvent) => {
    if ((inputRef.current && !inputRef.current.contains(event.target as Node)) &&
    (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node))) {
      //console.log(`setting show questions to false`)
      setShowQuestions(false)
    }
  };

  useEffect(() => {
    // Add the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);

  };

  const handleSendClick = () => {
    if (message.trim() !== "") {
      sendChat(message);
      setMessage("");
    }
  };

  const handleSuggestedQuestionClick = (e: React.MouseEvent, m: string) => {
    e.stopPropagation()
    setShowQuestions(false);
    sendChat(m);
    
  }

  const getSuggestedQuestions = async () => {
    const response: FetchQuestionResult = await fetchSampleQuestions();
    response && setSuggestedQuestions(response.questions);
  }

  const handleMagicClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSuggestedQuestions([])
    //console.log(`settings how questions to true`)
    setShowQuestions(true)
    getSuggestedQuestions()
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    // //console.log(`key pressed: ${e.key}`)
    if (e.key === 'Enter' && !e.shiftKey) {  // Check for Enter key without Shift key
      e.preventDefault();  // Prevent default to avoid line break in TextField
      handleSendClick();
    }
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={1} width='100%' mt={1}>
        <Grid item xs={1} />
        <Grid item xs={9} sx={{ position: 'relative' }}>
          <ChatInputField
            ref={inputRef}
            placeholder="Chat with me!"
            variant="outlined"
            value={message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Suggest a question for me">
                    <IconButton onClick={handleMagicClick} disabled={appStatus !== AppStatus.Idle} aria-label="Magic icon">
                      <AutoAwesomeIcon fontSize="medium" sx={{ color: theme.palette.primary.dark }} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            fullWidth
            disabled={appStatus !== AppStatus.Idle}
          />
          {showQuestions && (
            <SuggestedQuestionsContainer ref={suggestionsRef}>
              {suggestedQuestions && suggestedQuestions.length === 0 ? (
                <Box width={'100%'}>
                <Box height={'50px'}/>
                <CircularProgress />
                </Box>
              ) : (
                suggestedQuestions.map((item, index) => (<QuestionItemContainer
                  key={index}
                  onClick={(event) => handleSuggestedQuestionClick(event, item)}  // Handle click for suggested question
                >
                  <Typography variant='body3' component={'span'} textAlign={'left'} width='100%'>
                    {item}
                  </Typography>
                  <Box height='15px' />
                  {index !== 0 && <SearchDivider />}
                </QuestionItemContainer>
                ))
              )}
            </SuggestedQuestionsContainer>
          )}
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", justifyContent: "start" }}>
          <IconButton onClick={handleSendClick} disabled={appStatus !== AppStatus.Idle} aria-label="Send icon">
            <SendIcon fontSize="medium" sx={(theme) => ({
              color: theme.palette.primary.dark,
            })} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChatInput;
