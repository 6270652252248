import React, { useState, useEffect, useRef } from "react";
import { Grid, Typography, Box, Button, TextField } from "@mui/material";
import { ChooseSingleOption, attributeChoiceProps } from "./attributeChoices";
import { ChoiceOptions, UserModel, AgeRange, GenderOptions, SchoolStatusOptionsType, School, EnrollmentOptions, CreditsCompleted, EnrollmentOptionsType, CreditsCompletedType } from '../../model/nodeTypes';
import fetchSchools from '../../api/user/fetchSchools';
import {useMediaQuery} from "@mui/system";
import { useAppContext } from "../app/appContext";
interface ProfileUserFormProps {
    isEditing: boolean;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ProfileFieldProps {
    label: string;
    name: string;
    value: string;
    isEditing: boolean;
    component: React.FC<attributeChoiceProps>;
    componentProps: attributeChoiceProps;
}

const ProfileField: React.FC<ProfileFieldProps> = ({
    label,
    value,
    isEditing,
    component: Component,
    componentProps
}) => (
    <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
        <Grid item xs={4}>
            <Typography variant="body1" align="right" fontWeight={"bold"}>
                {label}
            </Typography>
        </Grid>
        <Grid item xs={8}>
            {isEditing ? (
                <Component {...componentProps} />
            ) : (
                <Typography variant="body1" align="left">
                    {value}
                </Typography>
            )}
        </Grid>
    </Grid>
);

const ProfileUserForm: React.FC<ProfileUserFormProps> = ({
    isEditing,
    setIsEditing,
}) => {
    const {isphone, setProfile, profile} = useAppContext();
    const MapOptionList = (options: School[]): string[] => {
        return options.map((option) => option.school_name);
    };
    const [formValues, setFormValues] = useState({
        first_name: profile?.first_name || '',
        school_name: profile?.is_primary_school?.[0]?.school_name || '',
        enrollment_status: profile?.is_primary_school?.[0]?.enrollment_status || profile?.enrolled_at_school?.[0]?.enrollment_status || '',
        credits: profile?.is_primary_school?.[0]?.credits_earned || "",
        gender: profile?.gender || "",
        age: profile?.age || "",
    });

    const formValuesRef = useRef(formValues);

    const [schoolOptions, setSchoolOptions] = useState<School[]>([]);

    const handleSelectChange = (name: string, value: string) => {
        setFormValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        //console.log("Form Submitted", formValues);
        setIsEditing(false);
        if (JSON.stringify(formValuesRef.current) !== JSON.stringify(formValues)) {
            formValuesRef.current = formValues;  // Update the ref with current form values
            formValues.school_name && setProfile((prev) => {
                if (prev) {
                    return {
                        ...prev,
                        first_name: formValues.first_name || "",
                        age: formValues.age as AgeRange || null,
                        is_primary_school: [{
                            school_name: formValues.school_name,
                            enrollment_status: formValues.enrollment_status as SchoolStatusOptionsType || null,
                            credits: formValues.credits as CreditsCompletedType || null,
                        }],
                        gender: formValues.gender as GenderOptions || null,
                    };
                }
                return prev;
            });
        }
    };


  const uniqueSchoolNames = Array.from(
    new Set(
      schoolOptions
        .map((option) => option.school_name)  // Map to extract school names
        .filter((school_name) => school_name !== null && school_name !== undefined)  // Filter out null or undefined values
    )
  );


    const getSchools = async () => {
        try {
          await fetchSchools(setSchoolOptions);
        } catch (error) {
          console.error('Error fetching schools:', error);
        }
      };

    useEffect(() => {
        if (!schoolOptions || schoolOptions.length === 0) {
          getSchools();
        }
      }, []);
    
  
    // Configuration array for form fields
    const fieldsConfig = [
        {
            label: "School:",
            name: "school_name",
            options: uniqueSchoolNames || [],
            component: ChooseSingleOption,
        },
        {
            label: "Enrollment Status:",
            name: "enrollment_status",
            options: Object.values(EnrollmentOptions) || [],
            component: ChooseSingleOption,
        },
        {
            label: "Credits Completed:",
            name: "credits",
            options: Object.values(CreditsCompleted) || [],
            component: ChooseSingleOption,
        },
        {
            label: "Gender:",
            name: "gender",
            options: Object.values(GenderOptions) || [],
            component: ChooseSingleOption,
        },
        {
            label: "Age:",
            name: "age",
            options: Object.values(AgeRange) || [],
            component: ChooseSingleOption,
        },
    ];

    return (
        <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
        >
            <Typography variant="h4" gutterBottom align="left">
                Personal Info
            </Typography>
            <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid item xs={(!isEditing && !isphone)? 6 : 12}>
                    <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
                        <Grid item xs={4}>
                            <Typography variant="body1" align="right" fontWeight={"bold"}>
                                Name:
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            {isEditing ? (
                                <TextField
                                    name="first_name"
                                    value={formValues.first_name}
                                    onChange={(e) => handleSelectChange("first_name", e.target.value)}
                                    fullWidth
                                />
                            ) : (
                                <Typography variant="body1" align="left">
                                    {profile?.first_name}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {fieldsConfig.map((field) => (
                    <Grid id='grid-form-item' item 
                    key={field.name} 
                    xs={(!isEditing && !isphone)? 6 : 12} 
                    sx={{
                    '&.MuiGrid-item': {
                        paddingLeft: '0px',
                      },}}>
                        <ProfileField
                            label={field.label}
                            name={field.name}
                            value={formValues[field.name as keyof typeof formValues]}
                            isEditing={isEditing}
                            component={field.component}
                            componentProps={{
                                options: field.options,
                                selectedValue: formValues[field.name as keyof typeof formValues],
                                setSelectedValue: (value: string) => handleSelectChange(field.name, value),
                            }}
                        />
                    </Grid>
                ))}

            </Grid>
            {
        isEditing && (
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
            </Button>
        )
    }
        </Box >
    );
};

export default ProfileUserForm;