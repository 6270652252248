import React from 'react';
import { Typography} from '@mui/material';
import { School, Handshake, Chat } from '@mui/icons-material'; // Import MUI icons
import {  Step, StepContainer, Line } from './shared';

interface stepProps {
    stage: string;
}

const FreSteps:React.FC<stepProps> = ({stage}) => {


  return (
    
      <StepContainer>
        <Step>
          <Typography variant="h3" color={
            stage==='school'? 'white' : '#a6a6a6' } gutterBottom>
            Choose your school
          </Typography>
        </Step>


        <Step>
          <Typography variant="h3" color={
            stage==='identity'? 'white' : '#a6a6a6' } gutterBottom>
            Tell us how we can help
          </Typography>
        </Step>


        <Step>
          <Typography variant="h3" color={
            stage==='chat'? 'white' : '#a6a6a6' }  gutterBottom>
            Start chatting
          </Typography>
        </Step>
      </StepContainer>
  );
};

export default FreSteps;