import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, Box, List, ListItem, SvgIconTypeMap, Typography } from '@mui/material';
import { mediaSize, gridSize } from '../fre/shared';
import { ButtonBox } from './shared';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import SmsIcon from '@mui/icons-material/Sms';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import HistoryIcon from '@mui/icons-material/History';
import PersonIcon from '@mui/icons-material/Person';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import { useAppContext } from './appContext';
interface MenuButtonProps {
  text: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string };
  selectedText: string;
  setSelectedText: (text: string) => void;

}
const MenuButton: React.FC<MenuButtonProps> = ({text, icon : Icon, selectedText, setSelectedText}) => {
    const [ishovered, setIsHovered] = useState(false);
    const [isselected, setIsSelected] = useState(selectedText === text);

    useEffect(() => {
      setIsSelected(selectedText === text);
    }, [selectedText, text]);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
  
    const handleClick = () => {
      setSelectedText(text);
    };

    return (
        <ButtonBox
        ishovered={ishovered}
        isselected={isselected}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      >
            <Icon 
            style={{
                color: 'white',
                marginRight: '10px',
            }}/>
            <Typography variant='caption' color="white">{text}</Typography>
        </ButtonBox>
    );
};
interface sideMenuProps {
   selectedText: string;
    setSelectedText: (text: string) => void;
}

export const SideMenu: React.FC<sideMenuProps> = ({ selectedText, setSelectedText }) => {
    const navigate = useNavigate();

    const changePage = () => {
        if (selectedText) {
        selectedText === 'PATH' ? navigate('../landing') :
        selectedText === 'CHAT' ? navigate('../chat') : 
        selectedText === 'HISTORY'? navigate('../history') : 
        selectedText === 'BOOKMARKS'? navigate('../resources') :
        selectedText === 'PROFILE'? navigate('../profile') : 
        selectedText === 'HELP'? 
        window.location.href = 'mailto:support@campusevolve.ai?subject=Help%20Request&body=Please%20describe%20your%20issue.' 
        : navigate('../resources');

        }
    };
    useEffect(() => {
        //console.log(`Selected Text: ${selectedText}`);
        changePage();
    }, [selectedText]);

    return (
      <Box sx={{
        id: 'sideMenu',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
        <Box width="100%">
            <List>
            <ListItem sx={{ width: "100%", padding: "0px" }}>
                <MenuButton text="PATH" icon={PersonPinCircleIcon} selectedText={selectedText} setSelectedText={setSelectedText} />
                </ListItem>
                <ListItem sx={{ width: "100%", padding: "0px" }}>
                <MenuButton text="CHAT" icon={SmsIcon} selectedText={selectedText} setSelectedText={setSelectedText} />
                </ListItem>
                <ListItem sx={{ width: "100%", padding: "0px" }}>
                <MenuButton text="HISTORY" icon={HistoryIcon} selectedText={selectedText} setSelectedText={setSelectedText} />
                </ListItem>
                <ListItem sx={{ width: "100%", padding: "0px" }}>
                <MenuButton text="BOOKMARKS" icon={TipsAndUpdatesIcon} selectedText={selectedText} setSelectedText={setSelectedText} />
                </ListItem>
            </List>
            </Box>
            <Box width="100%">
            <List>
            <ListItem sx={{ width: "100%", padding: "0px" }}>
                <MenuButton text="HELP" icon={HelpCenterIcon} selectedText={selectedText} setSelectedText={setSelectedText} />
                </ListItem>
                <ListItem sx={{ width: "100%", padding: "0px" }}>
                <MenuButton text="PROFILE" icon={PersonIcon} selectedText={selectedText} setSelectedText={setSelectedText} />
                </ListItem>
            </List>
            </Box>
        </Box>
    );
};
