import {Auth0Provider} from '@auth0/auth0-react';
import {useAuth0} from '@auth0/auth0-react';
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

const Auth0ProviderWithNavigate = ({children}) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  console.log(`auth provider wrapper mounted`)
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const onRedirectCallback = (appState) => {
    console.log('redirect triggered via call back from auth0')
    console.log(`redirect uri: ${redirectUri}`)
    debugger;
    if (redirectUri.startsWith('http')) {
      window.location.href = redirectUri;
    } else {
      navigate(redirectUri);
    }

  };

  if (!(domain && clientId && redirectUri && audience)) {
    console.log(`Auth0ProviderWithNavigate: 
    Missing configuration. ${domain} ${clientId} ${redirectUri} ${audience}`);
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audience,
        scope: `user`,

      }}
      onRedirectCallback={onRedirectCallback}
    >
      <TokenFetcher>{children}</TokenFetcher>
    </Auth0Provider>
  );
};

Auth0ProviderWithNavigate.propTypes = {
  children: PropTypes.node.isRequired,
};



const TokenFetcher = ({children}) => {
  const {isAuthenticated, getAccessTokenSilently} = useAuth0();
  const fetchToken = async () => {
    console.log(`token fetch wrapper mounted with isAuthenticated == ${isAuthenticated}`)
    if (isAuthenticated && getAccessTokenSilently) {
      console.log('fetching token from backend and setting session storage', isAuthenticated);
      
      try {
        const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: audience,
            scope: `user, openid`,
          },
        });
        sessionStorage.setItem('accessToken', token);
        console.log('able to fetch token')
      } catch (e) {
        console.log(`Unable to get access token for user: ${e.message}`);
      }
    }
  };

  useEffect(() => {
    console.log('use effect in auth provider triggered to fetch token')
    fetchToken();
  }, [isAuthenticated, getAccessTokenSilently]);
  return <>{children}</>;
};

TokenFetcher.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth0ProviderWithNavigate;
