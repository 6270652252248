import React from 'react';
import { Box, Button, Drawer } from '@mui/material';
import { styled,  } from '@mui/system';
import {  mediaSizeProps } from '../fre/shared';
import ExtendedBox from '../extendedComponent';


export const Root = styled(Box)({
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  position: 'relative',
  backgroundColor: '#00697c',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  padding: '0px',
  textAlign: 'center',
  margin: '0px',
});



interface StyledBoxProps {
    ishovered?: boolean;
    isselected?: boolean;
  }

export const DefaultButton = styled(Button)({
  backgroundColor: '#ffd523', // Default background color
  color: '#000', // Default text color
  '&:hover': {
    backgroundColor: '#EFC100', // Hover background color
  },
  '&:active': {
    backgroundColor: '#ffd523', // Background color when clicked
    boxShadow: 'none', // Removes the default box shadow on click
  },
  '&:focus': {
    outline: 'none', // Remove focus outline
  },
  borderRadius: '10px'
})

export const ButtonBox =  styled(ExtendedBox)<StyledBoxProps> 
(({ ishovered = false, isselected = false }) => ({
  display: 'flex',
  height: '30px',
  width: '100%',
  backgroundColor: isselected? '#00697c': ishovered? '#0097b2' : '#0097b2',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
  cursor: 'pointer',
  padding: '10px',
  transition: 'background-color 0.3s, color 0.3s',
}));

export const ChatButton = styled(Button)({
    marginBottom: '20px',
    marginLeft: '20px',
    display: 'flex',
    width: '80%',
    textTransform: 'none',
    color: '#fff',
    backgroundColor: '#0097b2',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '10px',
    borderRadius: '10px',
      // Hover state
  '&:hover': {
    backgroundColor: '#FFDD57', // Change this to your desired hover color
    color: '#000',
  },

  // Active (click) state
  '&:active': {
    backgroundColor: '#FFDD57', // Change this to your desired click color
    color: '#000',
  },
  });

export const RootRight = styled(Box)({
    position: 'absolute',
    marginRight: '20px',
    left: '160px',
    height: '100%',
    width: 'calc(100% - 160px)',
    backgroundColor: '#dfe0e0',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: ('0px 10px 10px 0px'),
    textAlign: 'center',
  });
  

export const Panel = styled(ExtendedBox)(({isphone})=>({
  position: 'absolute',
  top: isphone? '60px':'50px',
  marginRight: '20px',
  left: isphone? '70px' : '160px',
  overflowY: 'auto',
  height: '90%',
  width: isphone? 'calc(100% - 80px)': 'calc(100% - 180px)',
  backgroundColor: '#F2F2F2',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  borderRadius: isphone? ('10px 10px 10px 10px') : ('0px 10px 10px 0px'),
  textAlign: 'center',
}));

export const LeftNav = styled(Box)({
  width: '140px',
  height: '90%',
  position: 'absolute',
  left: '20px',
  top: '50px',
  backgroundColor: '#0097b2',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: ('10px 0px 0px 10px'),
  textAlign: 'center',

});

export const PhoneDrawer = styled(Drawer)({
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: '0px',
  top: '0px',
  backgroundColor: '#0097b2',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  borderRadius: ('10px 0px 0px 10px'),
  textAlign: 'center',
});

export const HistoryContainer = styled(ExtendedBox)(({isphone})=>({
  marginTop: '20px',
  position:'relative',
  marginBottom: '20px',
  marginLeft: isphone? '0px':'80px',
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 40px)',
  width: isphone? 'calc(100% - 20px)' : 'calc(100% - 80px)',
}));

export const HistoryTimeline = styled(Box)({
  position: 'absolute',
  top: '50px',
  left: '0px',
  width: '100%',
  minHeight: 'calc(100% - 50px)',
  height: 'auto',
  borderLeft: '2px solid rgb(0, 0, 0, .5)',
});

export const TimelineLabelContainer = styled(ExtendedBox)(({isphone=false})=>({
  width: isphone? '200px':  '100px',
  overflow: 'visible',
  position: isphone? 'relative': 'absolute',
  height: 'auto',
  padding: '10px',
  left: isphone? '-20px': '-75px',
  top: isphone? '': '5px',
  borderRadius: '10px',
  backgroundColor: '#34c2de',
}));

export const TimelineItem = styled(ExtendedBox)(({isphone=false})=>({
  position: 'relative',
  display: 'flex',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
  flexDirection: isphone? 'column': 'row',
  padding: '10px',
  marginLeft: '10px',
  marginBottom: '20px',
  backgroundColor: 'white',
  height: 'auto',
  borderRadius: '10px',
  width: isphone? 'calc(100% - 20px)' : 'calc(100% - 40px)',
}));

export const TimelineItemContent = styled(ExtendedBox)(({isphone=false})=>({
  display: 'flex',
  flexDirection: 'column',
  marginTop: isphone? '10px' : '20px',
  marginLeft: isphone? '10px' : '50px',
  alignItems: 'flex-start',
  width: isphone? '100%': 'calc(100% - 50px)',
}));

export const TimelineItemRow = styled(ExtendedBox)(({isphone=false})=>({
  display: 'flex',
  flexDirection: isphone? 'column': 'row',
  alignItems: 'flex-start',
  width: '100%',
  marginBottom: '10px',
}));