import React, { useEffect, useRef, useState } from "react";
import { TextField, Box, InputAdornment, Tooltip, Button, Typography, CircularProgress } from "@mui/material";
import { TopBar, SearchBar, SearchDivider, RecentSearchOptions, RecentTopicButton, XButton, SearchTextField } from "./shared";
import SearchIcon from '@mui/icons-material/Search';
import { ChangeEvent } from "react";
import { AnyMessage } from "../../model/nodeTypes";
import ChatBotChatResponse from "./chatElements/chatMessageElements/chatResponse/chatBotChatResponse";
import  { HistoryItems } from "../../api/history/fetchHistory";
import { useAppContext } from "../app/appContext";
import { ChooseSingleOption, ChooseSingleOptionSmall } from "../profile/attributeChoices";
import SchoolIcon from '@mui/icons-material/School';

// search results and get message history are in the parent, which required to interact with the chat history
// for dynamically scrolling to search results. 
interface ChatTopBarProps {
  searchResults: AnyMessage[];
  setSearchResults: (results: AnyMessage[]) => void;
  fetchMessageHistory: (query?: string) => void;
  setSelectedSearchResult: (result: AnyMessage | undefined) => void;
  searchInput: string | ''; 
  noSearchResults: boolean;
  setNoSearchResults: React.Dispatch<React.SetStateAction<boolean>>;  
  isSearchLoading: boolean; 
  setIsSearchLoading:  React.Dispatch<React.SetStateAction<boolean>>;  
}

const ChatTopBar: React.FC<ChatTopBarProps> = ({
  searchResults,
  setSearchResults,
  fetchMessageHistory,
  setSelectedSearchResult,
  searchInput,
  noSearchResults,
  setNoSearchResults,
  isSearchLoading,
  setIsSearchLoading
}) => {

  const [searchText, setsearchText] = React.useState<string>(searchInput);  // Pre-fill with the search text
  const searchBoxRef = useRef<HTMLDivElement>(null);
  const {history, uniqueSchoolNames, profile, setProfile, isphone} = useAppContext();
  const [topicList, setTopicList] = React.useState<string[]>([])
  const [selectedSchool, setSelectedSchool] = React.useState<string>(profile?.is_primary_school?.[0]?.school_name || 'Any')
  const [dialogState, setDialogState] = React.useState<string>('closed');
  const [isSearchFocused, setIsSearchFocused] = useState(false); // Track if search is focused
  const [isSchoolSelectorFocused, setIsSchoolSelectorFocused] = useState(false); // Track if school selector is focused
  const schoolSelectorRef = useRef<HTMLDivElement>(null);
  const handleSearchFocus = () => setIsSearchFocused(true);
  const handleSearchBlur = () => setIsSearchFocused(false);
  const currentSchoolRef = useRef<string>()
  const handleSchoolFocus = () => {
    setIsSchoolSelectorFocused(true);
    setTimeout(() => {
    console.log('focusing school selector')
    searchBoxRef.current?.focus(); // Manually focus the input when activated
  }, 0);
}

  const handleSchoolBlur = () => {
    console.log('school blur detected')
    setIsSchoolSelectorFocused(false)};

  const updateProfile = () => {
    setProfile((prev) => {
      if (!prev) return prev;
      const primarySchoolArray = prev.is_primary_school ?? [];
      return {
        ...prev,
        is_primary_school: [
          {
            ...primarySchoolArray[0], 
            school_name: selectedSchool,
          }
        ],
      };
    });
  }
 
  useEffect(() => {
    console.log(`school value is ${selectedSchool}`)
   //debugger
    if (selectedSchool != currentSchoolRef.current) {
     //debugger
      currentSchoolRef.current = selectedSchool
      updateProfile();
    }
  }, [selectedSchool]);

  const handleSearchEnter = async (queryString: string = '') => {
    clearResults()
    const modifyInput = queryString? queryString : searchText
    setIsSearchLoading(true);
    setDialogState('search');
    fetchMessageHistory(modifyInput);
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (searchBoxRef.current && !searchBoxRef.current.contains(event.target as Node)) {
      setDialogState('closed');
    }};

  const handleTopicClick = (text: string) => {
    setsearchText(text);
  }

  const clearResults = () =>{
    setNoSearchResults(false)
    setSearchResults([]);
    setDialogState('topic');
  }

  const clearSearch = () => {
    setsearchText("");
    clearResults();
  }

  const handleSearchResultClick = (result: AnyMessage) => {
    setSelectedSearchResult(result);
    setDialogState('closed');
  }

  const checkDialogState = () => {
    if (searchResults && searchResults.length > 0) {
      setDialogState('search');
    }
    else {
      setDialogState('topic');
    }
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setsearchText(e.target.value);
  }

  useEffect(()=>{
    if (history.length>0 && topicList.length==0) {
     //debugger
      const historyItem: HistoryItems = history[0]
      setTopicList(historyItem && historyItem.topics &&historyItem.topics?.split(',') || [])
    }
  },[topicList])
    
  useEffect(() => {
    if (searchInput){
      setsearchText(searchInput)
      handleSearchEnter(searchInput);
    }
  }, [searchInput]);

  useEffect(() => {
    // Add the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, []);

  return (
    <TopBar id='top-bar'>
           {/* School Selector */}
      <Box id='school-selector' sx={{ display: 'flex', alignItems: 'center' }}>
        {isphone && !isSchoolSelectorFocused ? (
          // Icon only for phones when not focused
          <Tooltip title="Select School">
            <SchoolIcon fontSize="large" onClick={handleSchoolFocus} />
          </Tooltip>
        ) : (
          // Expanded school selector when focused or on non-phone devices
          <Box ref={schoolSelectorRef} onBlur={handleSchoolBlur} sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <Typography>School: &nbsp;</Typography>
            <ChooseSingleOptionSmall
              selectedValue={selectedSchool}
              options={uniqueSchoolNames}
              setSelectedValue={setSelectedSchool}
              fontColor="black"
              marginProp="0"
            />
          </Box>
        )}
      </Box>
      <Box id='search-bar' position='relative' maxWidth='300px'>
      {isphone && !isSearchFocused ? (
          // Icon only for phones when not focused
          <Tooltip title="Search History">
            <SearchIcon fontSize="large" onClick={handleSearchFocus} />
          </Tooltip>
        ) : (
        <SearchBar isphone={isphone}
          onBlur={handleSearchBlur}
          ref={searchBoxRef}
          isselected={dialogState !== 'closed'}>
          <SearchTextField
            placeholder="Search chat"
            variant="outlined"
            value={searchText}
            onMouseDown={(e) => {
              checkDialogState();
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="search">
                    <SearchIcon fontSize="large" />
                  </Tooltip>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchText &&
                    <XButton
                      onClick={clearSearch}>
                      <span style={{ fontSize: '16px', fontWeight: '200', color: 'white' }}>x
                      </span></XButton >}
                </InputAdornment>
              )
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearchEnter(); // Call the function when Enter is pressed
              }
            }}
            onChange={handleSearch}
            fullWidth
          />
          {dialogState === 'topic' && topicList && topicList.length>0  && <><SearchDivider />

            <Typography variant="body3" sx={{ padding: '20px' }}>
              Recent
            </Typography>
           <RecentSearchOptions>
              {topicList.map((text) => (
                <RecentTopicButton variant='contained' onClick={() => handleTopicClick(text)} key={text} sx={{ padding: '10px' }}>
                  <span style={{ color: 'black' }}>{text}</span>
                </RecentTopicButton>
              ))}
            </RecentSearchOptions>
          </>}
          {dialogState === 'search' && <><SearchDivider />
            {noSearchResults? <>
             <Typography variant="body3" sx={{ paddingLeft: '20px', paddingTop: '20px'}}> 
              No search results were found for the keywords you entered.  Please change your search text and try again. 
             </Typography>
            </> : <>
              <Typography variant="body3" sx={{ paddingLeft: '20px', paddingTop: '20px'}}>
                Search Results
              </Typography>
              <RecentSearchOptions>
                {isSearchLoading ? (
                  <Box sx={{
                    display: 'flex',
                    width: '100%',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <CircularProgress sx={{ color: '#FFDD57' }} />
                  </Box>
                ) :<Box sx={{maxHeight: '400px', width: '98%', overflowY: 'auto'}}>{
                  searchResults.map((message) => (
                    message.role && message.role === 'system' &&
                    <Box
                      sx={{
                        m: "20px",
                        p: '10px',
                        cursor: 'pointer',
                        borderRadius: '10px',
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                      }}
                      key={message.object_id} // Ensure you have a unique key for each item
                      onClick={() => handleSearchResultClick(message)}
                      className="RecentSearchOptions"
                    >
                      {message.message_text &&
                        <ChatBotChatResponse
                          message={
                            message.message_text.length > 50
                              ? `${message.message_text.slice(0, 50)}...`
                                : message.message_text
                            }
                          />}
                        <p> </p>
                      </Box>
                    ))}
                    </Box>
                  }
              </RecentSearchOptions>
            </>}
          </>}

        </SearchBar>)}
      </Box>
    </TopBar>
  )
}

export default ChatTopBar;