import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Root, LeftNav, Panel, RootRight } from '../app/shared';
import { useMediaQuery } from '@mui/material';
import { mediaSize, gridSize } from '../fre/shared';
import { SideMenu } from '../app/leftNav';
import ProfileContent from './profileContent';
import LogoutButton from '../app/logout';
import AppContainer from '../app/appContainer';
import App from '../../App';
const Profile: React.FC = () => {

    const [selectedMenuItem, setSelectedMenuItem] = React.useState<string>("");
    const navigate = useNavigate();
    const isphone = useMediaQuery('(max-width:600px)');
    const istablet = useMediaQuery('(max-width:1024px)');
    const size = mediaSize({isphone, istablet}); 
    const gridValue = gridSize({isphone, istablet});
  
  return (
    <AppContainer>
      <ProfileContent />
    </AppContainer>
  );
};

export default Profile;