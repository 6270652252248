export interface StackTraceParse{
    errName: string
    functionLine: string
    callerLine: string
}

function parseStackTrace(error: Error): StackTraceParse{
    
    const stackLines = error.stack?.split("\n");
    
    if (stackLines && stackLines.length >= 3) {
      const errName = stackLines[0];
      const functionLine = stackLines[1];
      const callerLine = stackLines[2];  // The third line in the stack trace is the calling function
      return {errName: errName, functionLine: functionLine, callerLine: callerLine}
    }
    
    return {errName: '', functionLine: '', callerLine: ''}
  }


  export default parseStackTrace;