import React from "react";
import ReactMarkdown from 'react-markdown';
import { Box } from "@mui/material";
const TermsMarkdown = `

**Last Updated: 8/22/2024**

We really hope you get a lot out of using our App in your learning journey. But before you start, our lawyers asked us to have you read this. The following is a message that they really want to make sure you see before using the App.

By using the CampusEvolve App, you acknowledge and agree that the services provided may involve the use of artificial intelligence technologies (“**AI**”). These AI driven features are designed to enhance your experience by providing personalized recommendations and facilitating interactions within the CampusEvolve App. The AI may process your data, including but not limited to, user inputs, behavioral data, and interaction history to deliver more accurate and tailored responses. While we strive for high accuracy and reliability, AI technologies may occasionally produce results that are unexpected or inaccurate. By continuing to use the app, you consent to the use of AI as described and understand the potential limitations and risks associated with it.

YOU ARE RESPONSIBLE FOR CAREFULLY READING THESE TERMS. PAY SPECIAL ATTENTION TO THE BINDING ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER PROVISIONS AS THESE REQUIRE YOU TO USE ARBITRATION IN THE EVENT OF A DISPUTE UNDER THESE TERMS INSTEAD OF JURY TRIAL(S) OR CLASS ACTION(S).

These Terms of Service are a legally binding Terms (“**Terms**”) between you and CampusEvolve LLC (“**CampusEvolve**”, “**we**” or “**us**”). These Terms governs your use of any CampusEvolve service or technology associated with CampusEvolve including all websites, apps (digital technology in for form of software programs designed to run on a computing device, such as a smartphone, tablet, or computer), domains and subdomains at campusevolve.ai., and all domains and sub-domains of <www.campusevolve.ai>, all application programming interfaces, and all applications and other tools provided by CampusEvolve (collectively, the “**CampusEvolve App**”).

1. **YOUR ACCEPTANCE OF THESE TERMS**

All references to “**User**” “**you**” or “**your**” mean the person who in any way accesses or uses the CampusEvolve App, including each of your assignees, successors, and heirs. BY USING THE CAMPUSEVOLVE APP, YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO THE TERMS CONTAINED IN THESE TERMS, YOU ARE PROHIBITED FROM ACCESSING OR USING THE CAMPUSEVOLVE APP.

You acknowledge that you are able to electronically receive, download, and print these Terms, any other terms and policies incorporated into these Terms by reference, and any amendments to these Terms.

1. **MODIFICATIONS TO THESE TERMS**

You are responsible for regularly reviewing these Terms at the URL <www.CampusEvolve.ai/terms>, CampusEvolve reserves the right, in its sole discretion, to modify these Terms, and any other documents and/or polices incorporated by reference in these Terms, at any time and without prior notice. CampusEvolve will notify you of changes by posting at the URL above. Any modifications to these Terms will become effective thirty (30) days after they are posted on the URL above. Your use of the CampusEvolve App after the expiration of the thirty (30) days shall constitute your consent to such modifications. If you do not agree, you are prohibited from accessing or using the CampusEvolve App.

1. **OTHER TERMS AND POLICIES**

These Terms includes and hereby incorporates by reference any other terms posted at \[<www.CampusEvolve.ai/terms\>] as may be in effect and modified by CampusEvolve from time to time.

1. **DEFINITIONS**

“**Account**” means a User account provided by CampusEvolve to enable access and use of the CampusEvolve App that is authorized by CampusEvolve.
“**Affiliate**” means any entity that, directly or indirectly, through one or more intermediaries, controls, is controlled by, or is under common control with CampusEvolve.  
“**Affiliated Institution**” means Client (as defined below) with which the User is affiliated.

“**Beta Version**” means pre-release services, features, or functions identified as alpha, beta, preview, early access, or words or phrases with similar meanings.

“**Client**” means any entity, institution, college, university, nonprofit, or public or private organization that is not an individual User, and that uses the CampusEvolve App and authorizes Users pursuant to the terms of Client Order between that Client and CampusEvolve.  
“**Dashboard**” means CampusEvolve's user interface for accessing and administering the Services that you may access via the CampusEvolve App.

“**Intellectual Property Rights**” means all patent rights, copyright rights, mask work rights, moral rights, rights of publicity, trademark, trade dress and service mark rights, goodwill, trade secret rights and other intellectual property rights as may now exist or hereafter come into existence, and all applications therefore and registrations, renewals and extensions thereof, under the laws of any state, country, territory or other jurisdiction.  
“**Personal Information**” means information that identifies you as a specific individual and can be used to contact or identify you; examples of Personal Information include your name, email address, postal address, social security number, and phone number.  
“**Third Party Application(s)**” means any software application functionality that is provided by Client or a third party and interoperates with CampusEvolve App.  
“**User**” means an individual acting in her/his own accord or an individual who is authorized by a Client to use the CampusEvolve App and to whom Client (or CampusEvolve at Client’s request) have supplied a user identification and password. Users may include, for example, Client’s employees, consultants, contractors and agents, and third parties engaged in business transactions with Client.  
“**User Data**” means any data including Personal Information, feedback, content, text, photographs, images, video, music, or other information that you post to any part of the CampusEvolve App or provide to CampusEvolve via the CampusEvolve App or any Third Party Applications.

1. **USE OF THE CAMPUSEVOLVE APP**
    1. **License.** Subject to the terms and conditions of these Terms, you are hereby granted a non-exclusive, limited, non-transferable, and revocable license to use the CampusEvolve App solely as permitted by the functionality of the CampusEvolve App, which may vary by User. We reserve all rights not expressly granted in these Terms. We may terminate this license or your access to the CampusEvolve App at any time for any reason or no reason.
    2. **Eligibility.** To use the CampusEvolve App, you must register for an Account. To register for an Account, you must be, and hereby represent that you are, a legal entity or an individual 13 years or older who can form legally binding contracts. The CampusEvolve App is not designed for use by or in connection with anyone under the age of 13, and you accept any and all liability that may arise from your use of the CampusEvolve App relating to anyone under the age of 13. Any use or access of the CampusEvolve App by anyone under the age of 13 is strictly prohibited and in violation of these Terms. If you have previously been removed from the CampusEvolve App, you are prohibited from creating an Account and doing so is in violation of these Terms.
    3. **Accounts.** By registering for an Account, you agree to provide true, accurate, and complete information on all registration and other forms you access on the CampusEvolve App or provide to us and to update your information to maintain its truthfulness, accuracy, and completeness.
        1. **Verification.** When you register for an Account and from time to time thereafter, your Account will be subject to verification. You authorize CampusEvolve, directly or through third parties, to make any inquiries necessary to validate your identity and confirm your ownership of your email address, subject to applicable law.
        2. **Security.** When you register for an Account, you will be asked to provide an email account for verification and you may choose to enter your own password or authenticate through an existing social media account. You are entirely responsible for safeguarding and maintaining the confidentiality of your Account and your username and password. You authorize CampusEvolve to assume that any person using the CampusEvolve App with your email and password, either is you or is authorized to act for you. Furthermore, you are responsible for any and all activities and conduct, whether by you or anyone else that are conducted through your Account. You agree to notify us immediately if you suspect or become aware of any unauthorized use of your Account or any unauthorized access to your password. You further agree not to use any email, or password of another User of the CampusEvolve App that you are not authorized to use. CampusEvolve is not liable for any loss that you may incur as a result of someone else using your password or Account, either with or without your knowledge.
    4. **Beta Version**. You may choose to use the Beta Version in your sole discretion. Use of Beta Version may be subject to additional terms. The Beta Version of the AI system may not operate error-free, chat responses and information may be inaccurate, and chat responses to questions may be slow, taking longer than 20 seconds to generate. The Beta Version may be changed at any time without notice and may not be maintained and/or become generally available. The Beta Version is to be used for CampusEvolve's internal testing and evaluation purposes. CampusEvolve will have no liability arising out of or in connection with Beta Version and disclaims any warranty, indemnity, support, service level, or other obligations with respect to its Beta Version. YOU USE THE BETA VERSION AT ITS OWN RISK.
    5. **Rules and Restrictions.** CampusEvolve reserves the right, in our sole discretion, to refuse, suspend, or revoke your access to the CampusEvolve App upon discovery that any information you provided on any form or posted on the CampusEvolve App is not true, accurate, or complete, or such information or other conduct otherwise violates these Terms, or for any other reason or no reason in CampusEvolve’s sole discretion. In connection with your use of the CampusEvolve App, you acknowledge and agree that you will not:
        1. Target or harm minors in any way;
        2. Transmit any information which may invade another’s right of privacy;
        3. Impersonate any person or entity, including any User;
        4. Post or transmit any material that contains a virus or corrupted data with the intent to damage or otherwise disrupt another computer;
        5. Use the CampusEvolve App in a manner that adversely affects the availability of its resources to other Users. This includes, but is not limited to: use of all caps, flooding continuous postings of repetitive text, harassing other members, or attempting to solicit personally identifying information of another member;
        6. Post or transmit any advertising, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes” or any other form of solicitation without written consent of CampusEvolve;
        7. Post, transmit, or otherwise make available any material that you do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships, or under nondisclosure agreements);
        8. Post, transmit, or otherwise make available any material that infringes trademark, trade secret, copyright or other proprietary rights of any party;
        9. Attempt to disrupt or damage the CampusEvolve App in any fashion or otherwise intentionally attempt to impair CampusEvolve’s servers or computers;
        10. Attempt to gain unauthorized access to the CampusEvolve App, Accounts, computer systems or networks connected to CampusEvolve through hacking, password mining, scripts, “bots”, or any other means;
        11. Attempt to steal, mimic, etc. the look and feel, features, or intellectual property of CampusEvolve or any User(s);
        12. Make the CampusEvolve App or User Data available to, or use the CampusEvolve App or User Data for the benefit of any other party or disassemble or reverse engineer any or all of the CampusEvolve App;
        13. Access the CampusEvolve App to build a competitive product or service;
        14. Attempt to obtain any materials or information through any means not intentionally made available through the CampusEvolve App, including indexing any content of the CampusEvolve App for commercial or non-commercial purposes;
        15. Attempt to manipulate identifiers in order to disguise the origin of any post or transmitted material;
        16. Violate any local, state, national or international laws;
        17. Post, store, transmit, or otherwise make available any material that is or may be unlawful, harmful, threatening, abusive, harassing, false, misleading, tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy, hateful, or racially, ethnically, or otherwise objectionable;
        18. Interfere with any other User’s use and quiet enjoyment of the CampusEvolve App; and/or
        19. Collect or store User Data or other information about other Users in connection with the prohibited conduct and activities set forth in the paragraphs above.
    6. Further, you represent that you are not: (x) a citizen or resident of a geographic area in which access to or use of the CampusEvolve App is prohibited by applicable law, decree, regulation, treaty, or administrative act; (y) a citizen or resident of, or located in, a geographic area that is subject to U.S. or other sovereign country sanctions or embargoes; or (z) an individual, or an individual employed by or associated with an entity, identified on the U.S. Department of Commerce’s Denied Persons or Entity List, the U.S. Department of Treasury’s Specially Designated Nationals or Blocked Persons Lists, or the U.S. Department of State’s Debarred Parties List or otherwise ineligible to receive items subject to U.S. export control laws and regulations or other economic sanction rules of any sovereign nation. You agree that if your country of residence or other circumstances change such that the above representations are no longer accurate, that you will immediately cease using the CampusEvolve App.
2. **Data Privacy and Confidentiality**

CampusEvolve is committed to protecting your privacy and maintaining the confidentiality of your personal information. Any data collected through your use of the CampusEvolve App, including personal details, usage patterns, and interactions, will be handled in accordance with our Privacy Policy. We implement robust security measures to safeguard your information against unauthorized access, disclosure, or misuse. Your data will only be used for purposes essential to the operation of the CampusEvolve App, such as enhancing user experience, improving services, and providing customer support. CampusEvolve does not share your personal information with third parties without your explicit consent, except as required by law. By using the CampusEvolve App, you agree to the collection, processing, and storage of your data as described, and acknowledge our commitment to protecting your privacy and confidentiality.

1. **CONTENT AND THIRD PARTY INTERACTIONS**
    1. **Sharing Personal Information with Client.** All use or disclosure of User Data including Personal Information will be shared or disclosed only in accordance with applicable law. You authorize us to share your Personal Information with your Affiliated Institution as needed to verify your registration on the CampusEvolve App, to enable aggregate reporting of usage of the CampusEvolve App, and to facilitate your use of the CampusEvolve App. You will be given the opportunity to opt-in to disclosures for sharing and marketing purposes through the CampusEvolve App. If you elect to participate in the CampusEvolve App, the CampusEvolve App requires use or disclosure of Personal Information in order to fulfill your requests on the CampusEvolve App. The use of the CampusEvolve App is at your own risk. CampusEvolve does not assume liability for or relating to the delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the CampusEvolve App. Moreover, CampusEvolve makes no warranty, guarantee or assurance regarding the security of the information transmitted to CampusEvolve while in the process of transmission.
    2. **Dealings with Other Third Parties.** Your participation, correspondence or business dealings with any third party or any other terms, conditions, representations or warranties associated with a third party found on or through the CampusEvolve App, are solely between you and such third party and you agree that CampusEvolve shall not be responsible or liable for any loss, damage, or other matters of any sort incurred as the result of such dealings. CampusEvolve is not responsible for any interactions between Users of the CampusEvolve App. BEFORE CONSIDERING TO MEET ANOTHER USER IN PERSON OR OTHERWISE HAVE INTERACTION, USERS SHOULD CONTEMPLATE ANY BASIC SAFETY CONCERNS.
    3. The links on the CampusEvolve App will let you leave the particular portion of the CampusEvolve App or service you are accessing in order to access a linked site (the “**Linked Sites**”). CampusEvolve provides these links as a convenience, but does not control or endorse these Linked Sites, nor has CampusEvolve reviewed or approved the content which appears on the Linked Sites for accuracy, obsolescence, or relevance. You acknowledge and agree that CampusEvolve shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of any of the links, content, goods or services available on or through the Linked Sites.
    4. **Third Party Vendors.** CampusEvolve may use vendors, contractors or other third-party service provides to help provide the CampusEvolve App to you, and we may change our use of such vendors and contractors at our sole discretion and without notice to you.
2. **INTELLECTUAL PROPERTY**
    1. CampusEvolve retains all right, title, and interest to the CampusEvolve App, including without limitation, all software used to provide the CampusEvolve App and all trademarks, service marks, logos, trade names and any other proprietary designations reproduced through the CampusEvolve App. These Terms does not grant User any of CampusEvolve’s or of CampusEvolve’s licensors or CampusEvolve suppliers’ Intellectual Property Rights in the CampusEvolve App. User agrees to comply with all copyright and other intellectual property notices accessed through the CampusEvolve App.
    2. You acknowledge and agree that all feedback, comments, questions and suggestions (“**Feedback**”) you provide to CampusEvolve are the sole and exclusive property of CampusEvolve. You hereby irrevocably assign to CampusEvolve and agree to irrevocably assign to CampusEvolve all of your right, title, and interest in and to all Feedback, including without limitation all Intellectual Property Rights therein, and waive any moral rights you may have. At our request and expense, you will undertake any tasks we may reasonably request to assist us in acquiring, perfecting and maintaining Intellectual Property Rights and other legal protections for the Feedback. CampusEvolve shall have no obligation concerning the Feedback, including but not limited to no obligation to return any materials or acknowledge receipt of any Feedback. By sending or otherwise transmitting Feedback to CampusEvolve, you represent and warrant that you have the right to disclose the Feedback and the Feedback does not violate the rights of any other person or entity. This Feedback section shall survive any termination of your Account or the CampusEvolve App.
3. **DISPUTE RESOLUTION**

**PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT.**  
<br/>You and CampusEvolve agree that these Terms affect interstate commerce and that the Federal Arbitration Act governs the interpretation and enforcement of these arbitration provisions.  
<br/>This Section is intended to be interpreted broadly and governs any and all disputes between us including but not limited to claims arising out of or relating to any aspect of the relationship between us, whether based in contract, tort, statute, fraud, misrepresentation or any other legal theory; claims that arose before these Terms or any prior Terms (including, but not limited to, claims related to advertising); and claims that may arise after the termination of these Terms. **The only disputes excluded from this broad prohibition are the litigation of certain intellectual property and small court claims, as provided below.**  
<br/>Most disputes can be resolved by contacting CampusEvolve. You can reach CampusEvolve’s support department at <support@CampusEvolve.ai>. We both agree to use our best efforts to settle any dispute, claim, question, or disagreement directly through consultation with the CampusEvolve support department, and good faith negotiations shall be a condition to either of us initiating any other process or proceeding.

- 1. **Arbitration.** If we have not been able to resolve a dispute after sixty (60) days, we each agree to resolve any claim, dispute, or controversy (excluding any claims for injunctive or other equitable relief as provided below) arising out of or in connection with or relating to these Terms, or the breach or alleged breach thereof (collectively, “**Claims**”), by binding arbitration by JAMS, under the Optional Expedited Arbitration Procedures then in effect for JAMS, except as provided herein. JAMS may be contacted at <www.jamsadr.com>. The arbitration will be conducted vitually, unless you and CampusEvolve agree otherwise. Each party will be responsible for paying any JAMS filing, administrative and arbitrator fees in accordance with JAMS rules, and the award rendered by the arbitrator shall include costs of arbitration, reasonable attorneys’ fees and reasonable costs for expert and other witnesses. If you are an individual using the CampusEvolve App for non-commercial purposes: (i) JAMS may require you to pay a fee for the initiation of your case, unless you apply for and successfully obtain a fee waiver from JAMS; (ii) the award rendered by the arbitrator may include your costs of arbitration, your reasonable attorney’s fees, and your reasonable costs for expert and other witnesses; and (iii) you may sue in a small claims court of competent jurisdiction without first engaging in arbitration, but this does not absolve you of your commitment to engage in the informal dispute resolution process. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. Nothing in this Section shall be deemed as preventing CampusEvolve from seeking injunctive or other equitable relief from the courts as necessary to prevent the actual or threatened infringement, misappropriation, or violation of our data security, Intellectual Property Rights or other proprietary rights.
  2. **Class Action and Jury Trial Waiver.** WITH RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS OF WHETHER THEY HAVE OBTAINED OR USED THE CAMPUSEVOLVE APP FOR PERSONAL, COMMERCIAL OR OTHER PURPOSES, ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND CAMPUSEVOLVE ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY KIND.
  3. **Limitation on Time to File Claims**. ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE APP OR SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
  4. **Exceptions: Intellectual Property; Small Claims.** Notwithstanding your and our decision to resolve all disputes through arbitration, either of us may bring enforcement actions, validity determinations or claims arising from or relating to theft, piracy or unauthorized use of intellectual property in state or federal court or in the U.S. Patent and Trademark Office to protect its Intellectual Property Rights. Either party may also seek relief in a small claims court for disputes or claims within the scope of that court’s jurisdiction.

1. **DISCLAIMERS**

YOU AGREE NOT TO RELY ON THE CAMPUSEVOLVE APP, ANY INFORMATION ON THE CAMPUSEVOLVE APP OR THE CONTINUATION OF THE CAMPUSEVOLVE APP. CAMPUSEVOLVE EXPRESSLY DISCLAIMS ALL WARRANTIES AND LIABILITY FOR ERROR OR INTERRUPTION OF USE OR FOR LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS, OR THAT CAMPUSEVOLVE WILL CORRECT ALL CAMPUSEVOLVE APP ERRORS. USER ACKNOWLEDGES THAT CAMPUSEVOLVE DOES NOT CONTROL THE TRANSFER OF DATA OVER COMMUNICATIONS FACILITIES, INCLUDING THE INTERNET, AND THAT THE CAMPUSEVOLVE APP MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF SUCH COMMUNICATIONS FACILITIES. CAMPUSEVOLVE IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH PROBLEMS OR FOR ANY CONTENT OBTAINED BY CAMPUSEVOLVE FROM PUBLICLY AVAILABLE SOURCES OR THIRD PARTIES AND MADE AVAILABLE THROUGHT THE CAMPUSEVOLVE APP.  
<br/>EXCEPT AS OTHERWISE EXPRESSLY STATED IN THESE TERMS, CAMPUSEVOLVE DOES NOT MAKE ANY WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, AND CAMPUSEVOLVE SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, TO THE MAXIMUM EXTENT NOT PROHIBITED BY APPLICABLE LAW. CAMPUSEVOLVE DISCLAIMS ALL LIABILITY AND INDEMNIFICATION OBLIGATIONS FOR ANY HARM OR DAMAGES CAUSED BY ANY THIRD-PARTY HOSTING PROVIDERS. CONTENT IS PROVIDED “AS IS” AND THE CAMPUSEVOLVE APP IS PROVIDED “AS IS,” AND “AS AVAILABLE,” AND BOTH ARE PROVIDED EXCLUSIVE OF ANY WARRANTIES WHATSOEVER.

1. **RELEASE**

In addition to the recognition that CampusEvolve is not a party to any contract between Users, you hereby release CampusEvolve, our Affiliates, and our respective officers, directors, agents, subsidiaries, joint ventures, and employees from claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with any dispute you have with another User, whether it be at law or in equity. This release includes, for example and without limitation, any disputes regarding the performance, functions, and quality of any services provided by any User to any other User. Procedures regarding the handling of certain disputes between Users are discussed in Section 9 (Dispute Resolution).  
<br/>TO THE EXTENT APPLICABLE, YOU HEREBY WAIVE THE PROTECTIONS OF ANY LAW IN ANY OTHER APPLICABLE JURISDICTION WHICH SAYS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”  
<br/>This release will not apply to a claim that CampusEvolve failed to meet our obligations under these Terms.

1. **INDEMNIFICATION**

YOU WILL INDEMNIFY, DEFEND, AND HOLD HARMLESS CAMPUSEVOLVE, OUR AFFILIATES, AND OUR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, REPRESENTATIVES, AND AGENTS (EACH AN “**INDEMNIFIED PARTY**”) FROM ANY AND ALL CLAIMS, DAMAGES, LIABILITIES, COSTS, LOSSES, AND EXPENSES (INCLUDING, BUT NOT LIMITED TO, REASONABLE ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES) ARISING FROM OR RELATING TO ANY CLAIM, SUIT, PROCEEDING, DEMAND, OR ACTION BROUGHT BY YOU OR A THIRD PARTY OR OTHER USER AGAINST AN INDEMNIFIED PARTY RELATING TO: (A) USE OF THE CAMPUSEVOLVE APP BY YOU OR YOUR AGENTS, INCLUDING ANY PAYMENT OBLIGATIONS INCURRED THROUGH USE OF THE CAMPUSEVOLVE APP; (B) FAILURE TO COMPLY WITH THESE TERMS OR ANY OTHER TERMS OR POLICES BY YOU OR YOUR AGENTS; (D) FAILURE TO COMPLY WITH APPLICABLE LAW BY YOU OR YOUR AGENTS; (E) NEGLIGENCE, WILLFUL MISCONDUCT, OR FRAUD BY YOU OR YOUR AGENTS; AND (F) DEFAMATION, LIBEL, VIOLATION OF PRIVACY RIGHTS, UNFAIR COMPETITION, OR INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OR ALLEGATIONS THEREOF TO THE EXTENT CAUSED BY YOU OR YOUR AGENTS.

1. **LIMITATION OF LIABILITY**

CampusEvolve is not liable, and you agree not to hold us responsible, for any damages or losses arising out of or in connection with the Terms of Service, including, but not limited to:

- 1. your use of or your inability to use the CampusEvolve App;
  2. delays or disruptions in the CampusEvolve App;
  3. viruses or other malicious software obtained by accessing, or linking to, the CampusEvolve App;
  4. glitches, bugs, errors, or inaccuracies of any kind in the CampusEvolve App;
  5. damage to your hardware device from the use of the CampusEvolve App;
  6. the content, actions, or inactions of third parties’ use of the CampusEvolve App;
  7. a suspension or other action taken with respect to your Account;
  8. your reliance on the quality, accuracy, or reliability of metrics found on, used on, or made available through the CampusEvolve App; and
  9. your need to modify practices, content, or behavior or your loss of or inability to do business, as a result of changes to these Terms.  

ADDITIONALLY, IN NO EVENT WILL CAMPUSEVOLVE BE LIABLE FOR ANY SPECIAL, CONSEQUENTIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR INDIRECT COSTS OR DAMAGES, INCLUDING, BUT NOT LIMITED TO, LITIGATION COSTS, INSTALLATION AND REMOVAL COSTS, OR LOSS OF DATA, PRODUCTION, PROFIT, OR BUSINESS OPPORTUNITIES. THE LIABILITY OF CAMPUSEVOLVE, OUR AFFILIATES, OUR LICENSORS, AND OUR THIRD-PARTY SERVICE PROVIDERS TO ANY USER FOR ANY CLAIM ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR ANY OTHER TERMS OR POLICIES WILL NOT EXCEED ONE HUNDRED DOLLARS ($100). THESE LIMITATIONS WILL APPLY TO ANY LIABILITY, ARISING FROM ANY CAUSE OF ACTION WHATSOEVER ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR THE OTHER TERMS OF SERVICE, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH COSTS OR DAMAGES AND EVEN IF THE LIMITED REMEDIES PROVIDED HEREIN FAIL OF THEIR ESSENTIAL PURPOSE. SOME STATES AND JURISDICTIONS DO NOT ALLOW FOR ALL OF THE FOREGOING EXCLUSIONS AND LIMITATIONS, SO TO THAT EXTENT, SOME OR ALL OF THESE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.

1. **Disclaimer of Warranties**

YOUR USE OF THE APP AND SERVICES IS AT YOUR OWN RISK. THE APP AND THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER CAMPUSEVOLVE NOR ANY PERSON ASSOCIATED WITH CAMPUSEVOLVE MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE APP OR SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER CAMPUSEVOLVE NOR ANY PERSON ASSOCIATED WITH CAMPUSEVOLVE REPRESENTS OR WARRANTS THAT THE APP, ITS CONTENT OR ANY SERVICES, WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR APP OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE APP OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE APP WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.

CAMPUSEVOLVE HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.

THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.

1. **MISCELLANEOUS**
    1. **Export Controls**. You agree and acknowledge that you will comply will all relevant United States, and foreign jurisdiction, export and import laws when using our Services. You represent and warrant that you are not listed on any United States agency list of prohibited or sanctioned parties, and that you are not operating in, or for, a jurisdiction, nationality, or country (e.g. North Korea, Iran, Sudan, or Syria) that is subject to United States government embargo laws.
    2. **Notice to CampusEvolve**. For valid notice to CampusEvolve, including general comments, concerns, or Feedback, please send an email to [support@campusevolve.ai](mailto:support@campusevolve.ai); or mail, via first-class mail to: CampusEvolve LLC PO Box 227 Medina, WA 98039. We will send notices to you at your CampusEvolve account email address. Please keep that email address up to date. All notices of copyright infringement claims should be sent to the copyright agent designated above in the manner and by the means set forth therein.
    3. **Governing Law and Disputes.** these Terms and any dispute that may arise between you and CampusEvolve, will be governed in accordance with the internal laws of the State of Washington without giving effect to any choice or conflict of law provision or rule (whether of the State of Washington or any other jurisdiction). Because CampusEvolve controls and operates the CampusEvolve App from the United States, these Terms of Use are not intended to subject CampusEvolve to any laws or jurisdictions other than the United States. CampusEvolve does not represent or warrant that its Services is available or permissible in any particular jurisdiction. You warrant that your access and use of the App and Services is permissible under all applicable local laws, rules, regulations, and restrictions. CampusEvolve may, at our sole discretion, limit availability of the App or Service based on geography or jurisdiction.
    4. **Assignment.** You may not transfer these Terms or any rights and licenses granted under these Terms. These Terms may be assigned by CampusEvolve without restriction. Any attempted transfer or assignment in violation hereof shall be null and void.
    5. **Survival.** After these Terms terminates, the terms of these Terms and the other Terms of Service that expressly or by their nature contemplate performance after the Terms terminates or expires will survive and continue in full force and effect. For example, the provisions protecting Confidential Information, requiring arbitration, permitting audits, protecting intellectual property, requiring non-circumvention, indemnification, payment of fees, reimbursement and setting forth limitations of liability each, by their nature, contemplate performance or observance after these Terms terminates.
    6. **No Waiver.** No waiver of by the CampusEvolve of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure by CampusEvolve to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision for that instance or future instances of breach. If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect..
    7. **Entire Terms.** Except as expressly provided in a particular “legal notice” on the CampusEvolve App, these Terms constitutes the entire Terms between you and CampusEvolve with respect to the use of the CampusEvolve App.

For any questions or comments, or to report violations of these Terms, let us know, or contact us at <support@campusevolve.ai>.`

const PrivacyMarkdown = `
Data Privacy Policy

**Last updated: 8/22/2024**

# Introduction

This **Privacy Policy** describes how CampusEvolve LLC ("**we**," "**us**," or "**CampusEvolve**") processes personal information. We are committed to safeguarding the privacy and security of the personal information we collect from students, faculty, staff, and other users of our services. This Data Privacy Policy outlines how we collect, use, disclose, and protect personal information provided to us in relation to our services and is incorporated into the Terms of Service for the CampusEvolve App (“**CampusEvolve App,**” “AI Guide”). This Privacy Policy is agreed to when you consent to the CampusEvolve App Terms of Service. Please read this Privacy Policy carefully before using the AI Guide (“application,” “app,” or “service”).

# Information We Collect

**Personal Data**. We collect and process various types of personal information from students and users for the purpose of providing our services. This includes:

- Contact Information: Name, email address information for authentication.
- Academic Information: Program of study, stage of progress towards degree, research engagements, academic records, career planning, learning support needs, etc.
- Demographic Information: Age, gender, race or ethnicity, first language, nationality, etc, if provided voluntarily by the user.
- Social media profile information for authentication.

**Usage Data**. Usage Data refers to information on how the CampusEvolve App is used, including interactions with the platform, services used, communication, IP address, MAC address or other device identifiers, browser type and version, use of the CampusEvolve App, the time spent on the CampusEvolve App, the time and date of the use of the CampusEvolve App, as well as other diagnostic data.

**Tracking and Cookies Data**. We may use cookies and similar tracking technologies to operate and ensure safety of the CampusEvolve App, remember your preferences and settings, track your activity on the CampusEvolve App, authentication and session management, and/or for other purposes. Cookies are files with small amounts of data which may include an anonymous unique identifier. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze the CampusEvolve App. You can instruct your device to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of the CampusEvolve App.

# How We Use Personal Information

We are committed to ensuring that personal information collected is adequate, relevant and limited to what is necessary in relation to the purposes of processing and providing functionality in our solutions.

We use the collected personal information for the following purposes:

- To provide, administer, maintain and/or analyze the CampusEvolve App.
- To improve the CampusEvolve App.
- To communicate with you; including to send you information about the CampusEvolve App.
- To prevent fraud, criminal activity or misuse of the CampusEvolve App and to protect the security of our systems, architecture and networks.
- To comply with legal obligations and legal process and to protect our rights, privacy, safety or property, and/or that of our affiliates, you, or other third parties.

**Aggregated or de-identified information**. We may aggregate or de-identify Personal Information so that it may no longer be used to identify you and use such information to analyze the effectiveness of the CampusEvolve App, to improve and add features to the CampusEvolve App, to conduct research and for other similar purposes. In addition, from time to time, we may analyze the general behavior and characteristics of users of the CampusEvolve App and share aggregated information like general user data patterns and statistics with our clients. We may collect aggregated information through the CampusEvolve App, through cookies, and through other means described in this Privacy Policy. We will maintain and use de-identified information in anonymous or de-identified form, and we will not attempt to reidentify the information, unless required by law.

1. Children's Privacy:

We require that any person under the age of 13 obtain parental consent before using the App. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we may take steps to remove that information from our servers, which may result in the termination of the relevant account.

# Data Sharing and Disclosure

We do not share personal information with third parties who are not Users or Clients of our systems. We facilitate the sharing of personal information in the solutions we provide between users that are (a) authorized to see the information about others (i.e. organizations that are clients, faculty, staff, etc.) as defined by our organizational clients’ access and terms of service policies, and (b) have a specific need to see the information as part of regular assessment and usage of our solutions.

User data will only be shared with organizational clients upon obtaining explicit consent from the user, as outlined in our Privacy Policy. We disclose user data to our organizational clients strictly in accordance with this Privacy Policy and applicable legal requirements. We maintain transparency with our users about what data is shared and with whom, and we hold our organizational clients accountable for adhering to data protection standards.

We may disclose personal information to courts, law enforcement, governmental or public authorities, tax authorities, and authorized third parties, if and to the extent we are required or permitted to do so by law or where disclosure is reasonably necessary to: (i) comply with our legal obligations, (ii) comply with a valid legal request (such as a subpoena or court order) or to respond to claims asserted against CampusEvolve, (iii) respond to a valid legal request relating to a criminal investigation to address alleged or suspected illegal activity, or to respond to or address any other activity that may expose us, you, or any other of our users to legal or regulatory liability, (iv) enforce and administer our agreements with users or clients, including our Terms, Additional Legal Terms, and Policies, or (v) protect the rights, property or personal safety of CampusEvolve, its employees, or members of the public.

# Data Security

The security of your data is important to us. User data is shared with organizational clients using secure channels and encryption to protect against unauthorized access or disclosure. We employ industry-standard security measures to protect personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security. "

# Your Rights

Users have the following rights regarding their personal information:

- Access: Request access to personal information we hold about you.
- Correction: Request corrections to inaccurate or incomplete personal information.
- Deletion: Request deletion of personal information, subject to legal and contractual obligations.
- Withdraw Consent: If processing is based on consent, users can withdraw consent at any time.
- Data Portability: Request a copy of personal information in a machine-readable format.

# Retention Period

We retain personal information for as long as necessary to fulfill the purposes outlined in this policy, unless a longer retention period is required or permitted by law. Upon account termination or upon request, we will delete or anonymize user data in accordance with our data retention policy, unless further retention is required by law.

The following items describe our retention practices:

- Certain data may be retained beyond the standard retention period to comply with legal, tax, or regulatory obligations.
- After the retention period, user data may be anonymized and retained for analytical or statistical purposes, ensuring that the data can no longer be associated with any individual.
- At the end of a service contract, data may be retained for a specified period to allow for transition or data export before permanent deletion.
- Clients will be notified prior to the deletion of their users’ data, with an option to extend the retention period if needed.
- In exceptional cases, data may be retained for longer periods if there are ongoing legal proceedings or investigations.
- Data stored in backups may be retained for a longer period than the active data, but will be securely stored and protected from unauthorized access.
- We retain fully anonymized data indefinitely for the purpose of training, improving, and validating our AI models. This data has been stripped of all personally identifiable information and cannot be linked back to any individual.
- Once data is fully anonymized and used for AI model training, it is no longer possible to re-identify the individuals from whom the data was originally collected.

# Changes to the Privacy Policy

We reserve the right to modify this Privacy Policy at any time in accordance with applicable law. If we do so, we will post the revised Privacy Policy and update the “Last Updated” date at the top. We are committed to transparency in our use of anonymized data for AI model training and will regularly review our practices to ensure they meet the highest ethical standards.

# Contact Information

For any questions, concerns, or requests related to this Privacy Policy, please contact us at <support@campusevolve.ai>.

# Consent

By using our services, users consent to the collection, use, and sharing of your personal information as outlined in this Privacy Policy.
`


const Terms: React.FC = () => {
    return (
        <ReactMarkdown>
            {TermsMarkdown}
        </ReactMarkdown>
        );
};

export default Terms;


export const Privacy: React.FC = () => {
    return (
            <ReactMarkdown>{PrivacyMarkdown}</ReactMarkdown>);
};
