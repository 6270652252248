import React from 'react';
import { Box, Button, Drawer } from '@mui/material';
import { styled,  } from '@mui/system';
import { mediaSizeProps } from '../fre/shared';
import ExtendedBox from '../extendedComponent';

export const ResourceEmptyContainer = styled(ExtendedBox)(
({ isphone = false}) => ({
    display:'flex',
    flexDirection: isphone? 'column':'row',
    justifyContent:'start',
     alignItems:'center',
}));


export const ResourceEmptyTextContainer = styled(ExtendedBox) (() => ({
    display:'flex',
    flexDirection:'column',
    justifyContent:'start',
     alignItems:'start',
}));
