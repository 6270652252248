import react from "react";
import { Typography, Grid, Box } from "@mui/material";
import happy_hat from "../../assets/images/happy_hat.png";
import { ChatButton } from "../app/shared";

interface StarterWindowProps {
    handleButtonClick: (text: string) => void;
}
const StarterWindow: React.FC<StarterWindowProps> = ({ handleButtonClick }) => {
    return (
        <div id='starterWindow'>
            <img src={happy_hat} alt="happy_hat" height={'300px'} width={'300px'} />
            <Typography variant="h5" color="black" sx={{ marginTop: '-50px' }} >
                How can I help you today?
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: '20px', marginLeft: '0px', maxWidth: '100%' }}>
                <Grid item xs={12} sm={6}>
                    <ChatButton sx={{ marginTop: '20px' }} onClick={() => handleButtonClick("I need help with academic advising, but I don't know what kind of questions to ask.  Can you help me get started?")}>
                        <Box display='flex' flexDirection={'column'} alignItems={'flex start'} width='100%'>
                            <Typography variant="h6" color='inherit' gutterBottom>
                                Academic
                            </Typography>
                            <Typography variant="body1" color='inherit' textAlign='center' gutterBottom>
                                Do you need an advisor or tutor?
                            </Typography>
                        </Box>
                    </ChatButton>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <ChatButton onClick={() => handleButtonClick("I need financial help but I don't know what kinds of questions to ask.  Can you help me get started?")} sx={{ marginTop: '20px' }}>
                        <Box display='flex' flexDirection={'column'} alignItems={'flex start'} width='100%'>
                            <Typography variant="h6" color='inherit'gutterBottom>
                                Financial
                            </Typography>
                            <Typography variant="body1" color='inherit' textAlign='center' gutterBottom>
                                Let's find ways to get your education paid for!
                            </Typography>
                        </Box>
                    </ChatButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ChatButton onClick={() => handleButtonClick("I need help balancing life and school but I don't know what kind of questions to ask.  Can you help me get started?")}>
                    <Box display='flex' flexDirection={'column'} alignItems={'flex start'} width='100%'>
                            <Typography variant="h6" color='inherit' gutterBottom>
                                Wellbeing
                            </Typography>
                            <Typography variant="body1" color='inherit' textAlign='center'  gutterBottom>
                                Do you need support for health or family care?
                            </Typography>
                        </Box>
                    </ChatButton>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <ChatButton onClick={() => handleButtonClick("I need help figuring out how my studies align to a career, or what my career path should be, can you help me figure out what to ask?")}>
                    <Box display='flex' flexDirection={'column'} alignItems={'flex start'} width='100%'>
                            <Typography variant="h6" color='inherit' gutterBottom>
                                Career
                            </Typography>
                            <Typography variant="body1" color='inherit' textAlign='center'  gutterBottom>
                                Can I help you get started on a great job path?
                            </Typography>
                        </Box>
                    </ChatButton>
                </Grid>
            </Grid>
        </div>
    )
}

export default StarterWindow;