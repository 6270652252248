
import axios from 'axios';
import { BaseUrl } from "../baseURL";
import AppStatus from '../../model/conversation/statusMessages';
import { handleApiError } from '../apiErrorHandler';
interface chatResponseParams {
        messageText?: string, 
        setAppStatus?: (appStatus: AppStatus) => void,
        setErrorMessage?: (error: string) => void,
}

export interface ragResponse {
    topic: string;
    response: string;
    bot_object_id: string; 
    user_object_id: string;
}

export interface kickbackResponse {
    response: string;
    search_query: string;
    consideration_to_follow_up_on: string;
    follow_up_question: string;
}

export interface apiData {
    rag_response?: ragResponse;
    kickback_response?: kickbackResponse;
}
const fetchData = async ({
    messageText,
    setAppStatus,
    setErrorMessage,
}: chatResponseParams): Promise<apiData | undefined> => {
    try {
        setAppStatus && setAppStatus(AppStatus.GeneratingChatResponse);
        const apiUrl = `${BaseUrl()}/chat`;

        const requestConfig = {
            url: apiUrl,
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              'authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
            },
            data: {
              user_question: messageText,
            }
          };

          const response = await axios(requestConfig);

        const data: apiData = response.data;
        console.log(`recieved response from LLM: ${JSON.stringify(data)}`);
        //debugger;

        return {rag_response: data.rag_response, kickback_response: data.kickback_response};


      } catch (error: any) {
        throw handleApiError(error)
    }
    }
    
    export default fetchData;