import axios from 'axios';
import { BaseUrl } from "../baseURL";
import { UserModel } from '../../model/nodeTypes';
import { handleApiError } from '../apiErrorHandler';
interface SetProfileProps {
  userProfile: UserModel;
  callback?: () => void;
}

const setProfile = async ({userProfile, callback}: SetProfileProps) => {
  const apiUrl = `${BaseUrl()}/update-user`;
  
  try {
    console.log(`sending user profile as: ${JSON.stringify(userProfile)}`);
    const requestConfig = {
      url: apiUrl,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      data: userProfile,
    };

    callback && callback();

    const response = await axios(requestConfig);
    console.log('got response from server', JSON.stringify(response));

    if (response.status === 200) {
      console.log('got ok response from server triggering callback');
      sessionStorage.setItem('userProfile', JSON.stringify(userProfile));
      
      return;  // You may want to return some success message or data
    } else {
      console.error('Unexpected response format:', response);
      throw new Error('Unexpected response format');
    }
  } catch (error: any) {
    handleApiError(error)
}
}


export default setProfile;