import { Timestamp } from "./messages/messages";


export const LandingObject = {
  academic: [
    'Apply, enroll, transfer my credits',
    'Get a tutor or help with a class',
    'Choose classes for next semester',
  ],
  financial: ['Get my college or training paid for',
    'Find grants or scholarships',
    'Find a paid apprenticeship',
  ],
  wellbeing: ['Learn about diversity and inclusion programs',
    'cultural and personal support',
    'Help navigating campus clubs, activities and tech'
  ],
  career: ['Choose the right career for me',
    'Get tech skills for high demand jobs',
    'Get a job in my career path'
  ],
};

export const SchoolStatusOptions = {
    enrolled_full_time: 'Enrolled Full-time',
    enrolled_part_time: 'Enrolled Part-time',
    prospective_student: 'Prospective Student',
    alumni: 'Alumni',
    other: 'Other'
  } as const;

  export type SchoolStatusOptionsType = typeof SchoolStatusOptions[keyof typeof SchoolStatusOptions];


  export const CreditsCompleted = {
    one_to_thirty: '1-30 credits completed',
    thirty_to_sixty: '30-60 credits completed',
    over_sixty: 'Over 60 credits',
    dont_know: 'Don’t know'
  }

  export type CreditsCompletedType = typeof CreditsCompleted[keyof typeof CreditsCompleted];

  export const AlumniStatusOptions = {
    graduated: 'Graduated',
    withdrawn: 'Withdrawn',
    transferred: 'Transferred',
    other: 'Other'
  } as const;

  export  type AlumniStatusOptions = typeof AlumniStatusOptions[keyof typeof AlumniStatusOptions];

// Define Enums as objects with present values
export const EnrollmentOptions = {
    enrolled_full_time: 'enrolled full-time',
    enrolled_part_time: 'enrolled part-time',
  } as const;
  
  export type EnrollmentOptionsType = typeof EnrollmentOptions[keyof typeof EnrollmentOptions];
  
  export const LocationTypes = {
    remote: 'remote',
    on_site: 'on-site',
    hybrid: 'hybrid'
  } as const;
  
  export type LocationTypes = typeof LocationTypes[keyof typeof LocationTypes];
  
  export const SchedulePreferenceTypes = {
    morning: 'morning',
    afternoon: 'afternoon',
    evening: 'evening',
    night: 'night',
    flexible: 'flexible'
  } as const;
  
  export type SchedulePreferenceTypes = typeof SchedulePreferenceTypes[keyof typeof SchedulePreferenceTypes];
  
  export const ScheduleTypes = {
    full_time: 'full-time',
    part_time: 'part-time',
    contract: 'contract',
    hybrid: 'hybrid'
  } as const;
  
  export type ScheduleTypes = typeof ScheduleTypes[keyof typeof ScheduleTypes];
  
  export const RoleTypes = {
    internship: 'internship',
    contracting: 'contracting',
    apprenticeship: 'apprenticeship',
    regular_hire: 'regular hire',
    temporary: 'temporary',
    seasonal: 'seasonal',
    volunteer: 'volunteer',
    other: 'other'
  } as const;
  
  export type RoleTypes = typeof RoleTypes[keyof typeof RoleTypes];
  
  export const FeedbackOptions = {
    not_helpful: 'response was not helpful',
    incorrect: 'response was incorrect',
    incomplete: 'response was incomplete',
    confusing: 'response was confusing',
    took_too_long: 'took too long',
    other: 'other'
  } as const;
  
  export type FeedbackOptions = typeof FeedbackOptions[keyof typeof FeedbackOptions];

  export const FinancialAwardFields = {
    grant_name: 'grant_name',
    scholarship_name: 'scholarship_name',
    loan_name: 'loan_name',
  }

  export type FinancialAwardFields = typeof FinancialAwardFields[keyof typeof FinancialAwardFields];
  
  export const AgeRange = {
    under_18: 'under 18',
    age_18_26: '18-26',
    age_27_36: '27-36',
    age_37_46: '37-46',
    over_46: '>46',
    prefer_not_to_say: 'prefer not to say'
  } as const;
  
  export type AgeRange = typeof AgeRange[keyof typeof AgeRange];
  
  export const GenderOptions = {
    male: 'male',
    female: 'female',
    non_binary: 'non-binary',
    prefer_not_to_say: 'prefer not to say'
  } as const;
  
  export type GenderOptions = typeof GenderOptions[keyof typeof GenderOptions];

  export const IdentityOptions = {
      military_veteran: 'military veteran',
      parent: 'parent',
      racial_minority: 'racial minority',
      international_student: 'international student',
      lgbtq: 'lgbtq+',
      foster_youth: 'foster youth',
      non_traditional_student: 'non-traditional student',
      first_generation_college_student: 'first-generation college student',
      low_income: 'low income',
      disabled_person: 'disabled person',
      working_part_full_time: 'working part/full-time',
      tansfer_student: 'transfer student',
      highschool_student: 'highschool student',
      upskilling_for_career: 'upskilling in career/profession'
  } as const
  export type IdentityOptionsType = typeof IdentityOptions[keyof typeof IdentityOptions];
  
  // Define TypeScript export interfaces for Pydantic models
  export interface ApplicationRelProps {
    status?: string;
    date_applied?: Date;
    date_application_accepted?: Date;
    date_application_rejected?: Date;
    date_application_withdrawn?: Date;
  } 

  interface ExtendedBaseModel {
    created_at?: number;
    updated_at?: number;
    object_id?: string;
  } // Assuming this is a base interface for all models.

  export interface School extends ExtendedBaseModel {
    school_name: string;
    zip_code?: number;
  }
  
  export interface Domain extends School {
    domain_name: string;
  }
  
  export interface SubDomain extends Domain {
    subdomain_name: string;
  }
  
  export interface Page extends SubDomain {
    page_url: string;
    page_friendly_name?: string;
    page_description?: string;
    page_has_chunk: string[];
  }
  
  export interface Chunk extends Page {
    chunk_text?: string;
  }
  
  export interface Message extends ExtendedBaseModel {
    message_text?: string;
    role: string;
  }

  export  interface UserMessage extends Message {
    flagged_for_match?: MatchFlag[];
    user_id: string;
  }
  
  export  interface BotMessage extends Message {
    message_is_bookmarked?: boolean;
    uses_page?: Page[];
  }
  export interface MatchFlag extends ExtendedBaseModel {
    match_flag_type?: string;
  }

  export interface AnyMessage extends UserMessage, BotMessage, MatchRelModel {}
  
  export interface Accommodation extends ExtendedBaseModel {
    accommodation_name: string;
    accommodation_description?: string;
  }
  
  export interface IdentityGroup extends ExtendedBaseModel {
    identity_name: string;
    identity_description?: string;
  }
  
  export interface DisabilityStatus extends ExtendedBaseModel {
    disability_name: string;
    disability_type?: string;
    is_visible?: boolean;
    requires_accommodation: Accommodation[];
  }
  
  export interface SupportService extends ExtendedBaseModel {
    support_service_name: string;
    support_service_description?: string;
    support_service_format?: string;
    support_service_availability?: string;
    support_service_location?: string;
    support_service_eligibility_criteria?: string;
    disability_support: DisabilityStatus[];
    identity_support: IdentityGroup[];
  }
  
  export interface FinancialInstrument extends ExtendedBaseModel {
    instrument_name: string;
    instrument_description?: string;
    eligibility_criteria?: string;
    application_deadline?: Date;
  }
  
  export interface Scholarship extends FinancialInstrument {}
  
  export  interface Grant extends FinancialInstrument {}
  
  export  interface Loan extends FinancialInstrument {}
  
  export  interface Industry extends ExtendedBaseModel {
    industry_name: string;
    industry_description?: string;
  }
  
  export  interface Job extends ExtendedBaseModel {
    job_name: string;
    job_description?: string;
  }
  
  export  interface Organization extends ExtendedBaseModel {
    organization_name: string;
    organization_description?: string;
    offers_scholarship: Scholarship[];
  }
  
  export interface EmployerOrganization extends Organization {
    has_open_role: Job[];
    has_position: Job[];
  }
  
  export interface CommunityOrganization extends Organization {
    sponsored_by_organization: string[];
    sponsored_by_school: string[];
    supports_identity_group: IdentityGroup[];
    supports_disability: DisabilityStatus[];
    provides_org_networking_opportunities: Organization[];
    provides_industry_networking_opportunities: Industry[];
  }
  
  export interface StudentOrganization extends CommunityOrganization {
    is_student_run?: boolean;
    is_faculty_run?: boolean;
    is_alumni_run?: boolean;
    eligibility_criteria?: string;
  }
  
  export  interface Department extends School {
    department_name: string;
    department_description?: string;
  }
  
  export interface AcademicProgram extends School {
    program_name: string;
    department: Department[];
    program_description?: string;
    career_path: string[];
  }
  
  export interface Military extends ExtendedBaseModel {
    branch_name: string;
  }
  
  export interface License extends ExtendedBaseModel {
    license_name: string;
    license_description?: string;
    license_requirements?: string;
    license_exam_required?: string;
    license_exam_format?: string;
    license_exam_duration?: string;
    license_exam_score_required?: string;
    license_issued_by?: string;
    license_fee?: string;
    license_renewal_frequency?: string;
    license_continuing_education_requirements?: string;
  }
  
  export interface JobSkill extends ExtendedBaseModel {
    job_skill_name: string;
    job_skill_description?: string;
  }
  
  export interface JobRole extends Organization {
    job_role_name: string;
    job_role_schedule?: string;
    job_role_location?: string;
    job_role_type?: string;
    job_role_description?: string;
    job_role_preferred_qualifications?: string;
    job_role_educational_requirements?: string;
    license_requirements: License[];
    job_role_experience_requirements?: string;
    skills_requirement: JobSkill[];
    employer: Organization[];
  }
  
  export interface Degree extends School {
    degree_name: string;
    degree_type?: string;
    department: Department[];
    academic_program: AcademicProgram[];
  }
  
  export interface Course extends School {
    course_name: string;
    course_description?: string;
    course_credits?: number;
    course_level?: string;
    course_term?: string;
    course_year?: number;
    course_time_of_day?: string;
    course_days_of_week?: string;
    course_location?: string;
    course_instructor?: string;
    course_structure?: string;
    course_estimated_workload_hours?: number;
    department: Department[];
    major_requirement: AcademicProgram[];
    minor_requirement: AcademicProgram[];
  }
  
  export interface CourseRequirement extends Course {
    prerequisite: Course[];
    corequisite: Course[];
    concurrent_course: Course[];
    job_skills: string[];
  }
  
  export interface DegreeRequirements extends Degree {
    degree_requirements: Course[];
  }
  
  export interface Certificate extends ExtendedBaseModel {
    certificate_name: string;
    certificate_description?: string;
    department: Department[];
    academic_program: AcademicProgram[];
    job_skills: JobSkill[];
  }
  
  export interface OrganizationCertificate extends Certificate {
    organization: Organization[];
  }
  
  export interface SchoolCertificate extends Certificate {
    school: School[];
  }
  
  export interface Career extends ExtendedBaseModel {
    career_name: string;
    career_description?: string;
    job_skills: JobSkill[];
    industry: Industry[];
  }
  
  interface AdvisingCategory extends ExtendedBaseModel {
    advising_category_name: string;
    advising_category_description?: string;
    categorizes: string[];
  }
export interface AppliedSchool extends School,ApplicationRelProps {}
 
  export interface EnrolledSchoolProperties {
    enrollment_status: EnrollmentOptionsType;
    date_enrolled?: Date;
    planned_graduation_date?: Date;
    credits_earned?: string;
    preferred_learning_modality?: LocationTypes;
    preferred_class_schedule?: SchedulePreferenceTypes;
    academic_standing?: string;
  }
export interface EnrolledSchool extends School, EnrolledSchoolProperties {}


  export interface AlumniRelProps {
    alumni_status?: AlumniStatusOptions;
    date_student_graduated?: Date;
    date_student_withdrawn?: Date;
    date_student_transferred?: Date;
  }
  
  export interface PrimarySchoolStatus extends School {
    enrollment_status?: SchoolStatusOptionsType;
    credits_earned?: CreditsCompletedType;
  }

  export interface Alumni extends School, AlumniRelProps {}

  
  export interface CompletedCourseRelProps {
    course_grade?: string;
    date_course_completed?: Date;
    course_sentiment?: string;
  }

  export interface CompletedCourse extends Course, CompletedCourseRelProps {}
  
  export interface EmployedByRelProps {
    date_employment_started?: Date;
    date_employment_ended?: Date;
    employment_schedule_type?: ScheduleTypes;
    employment_role_type?: RoleTypes;
    employment_hours_per_week?: string;
    offers_tuition_assistance?: boolean;
    is_union_member?: boolean;
  }
  
 export interface Employment extends Organization, EmployedByRelProps {}

  export interface JobPerformanceRelProps {
    date_employment_started?: Date;
    date_employment_ended?: Date;
    employment_duties?: string;
  }
  
  export interface JobPerformance extends Organization, JobPerformanceRelProps {}
  
  export interface FinancialAwardRelProps {
    date_awarded?: Date;
    date_disbursed?: Date;
    award_amount?: string;
    award_type?: string;
    award_status?: string;
    loan_name?: string;
    scholarship_name?: string;
  }
  
export interface GrantAward extends Grant, FinancialAwardRelProps {}
export interface ScholarshipAward extends Scholarship, FinancialAwardRelProps {}
export interface LoanAward extends Loan, FinancialAwardRelProps {}
  
  export interface MilitaryRelProps {
    rank?: string;
    is_active_duty?: boolean;
    is_veteran?: boolean;
    is_reserve?: boolean;
    is_rotc?: boolean;
    date_enlisted?: Date;
    date_discharged?: Date;
  }

  export interface MilitaryService extends Military, MilitaryRelProps {}

  export interface UserSchoolAttributes {
    school_name: string;
    credits_completed?: string; 
    enrollment_status?: EnrollmentOptionsType;
    waitlisted_for_courses?: string[];
    enrolled_in_courses?: string[];
    considering_courses?: string[];
    completed_courses?: string[];
    interested_in_degrees?: string[];
    has_degrees?: string[];
    working_towards_degrees?: string[];
    interested_in_certificates?: string[];
    has_certificates?: string[];
    working_towards_certificates?: string[];
    interested_in_academic_programs?: string[];
    member_of_student_organization?: string[];
    needs_academic_support_service?: string[];
  }
  export interface NeedsRelModel {
    reason: string;
  }
export interface NeedsSupport extends SupportService, NeedsRelModel {}
  
  export interface MatchRelModel {
    score?: number;
    date_matched?: Date;
  }
  
  export interface RatingRelProps {
    sentiment: FeedbackOptions;
    rating_reason?: string;
    comment?: string;
    date_rated?: Date;
  }
  
export interface MessageRating extends UserMessage, RatingRelProps {}


  
  export interface UserModel {
    user_id: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    age?: AgeRange;
    gender?: GenderOptions;
    is_first_run?: boolean;
    is_tos_accepted?: boolean;
    applied_to_school?: AppliedSchool[];
    enrolled_at_school?: EnrolledSchool[];
    alumni_of_school?: Alumni[];
    interested_in_school?: School[];
    is_primary_school?: PrimarySchoolStatus[];
    waitlisted_for_course?: Course[];
    enrolled_in_course?: Course[];
    considering_course?: Course[];
    completed_course?: CompletedCourse[];
    interested_in_degree?: Degree[];
    has_degree?: Degree[];
    working_towards_degree?: Degree[];
    interested_in_certificate?: Certificate[];
    has_certificate?: Certificate;
    working_towards_certificate?: Certificate;
    interested_in_academic_program?: AcademicProgram[];
    career_mentor_in?: Career[];
    industry_experience_in?: Industry[];
    interested_in_career?: Career[];
    interested_in_job?: JobRole[];
    employed_by?: Employment[];
    interested_in_organization?: Organization[];
    received_loan?: LoanAward[];
    received_grant?: GrantAward[];
    received_scholarship?: ScholarshipAward[];
    member_of_student_organization?: StudentOrganization[];
    needs_academic_support_service?: NeedsSupport[];
    needs_non_academic_support_service?: NeedsSupport[];
    identified_as?: IdentityGroup[];
    qualified_for_accommodation?: Accommodation[];
    has_disability?: DisabilityStatus[];
    is_military?: MilitaryService[];
    bookmarked_resources?: Page[];
    rated_chat_response?: MessageRating[];
  }

  export interface ChoiceOptions {
    choice_option_name:  string;
    choice_value_label: string;
    choice_value_name: string;
}

export interface AppMetadata {
    property_name: string;
    property_description: string;
    display_label: string;
    data_type: string;
    display_name: string;
    choice_options?: ChoiceOptions[];
  }