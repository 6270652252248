import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';

import { SchoolStatusOptions, EnrollmentOptionsType, SchoolStatusOptionsType,  CreditsCompleted } from '../../model/nodeTypes';

import { useNavigate } from 'react-router-dom';
import { ContinueButton, Root, Spacer, mediaSize, gridSize } from './shared';
import FreSteps from './base';
import school_img from '../../assets/images/school.png';
import { ChooseSingleOption, ChooseRadioButtons } from '../profile/attributeChoices';
import { School } from '../../model/nodeTypes';
import { Handshake } from '@mui/icons-material';
import { useAppContext } from '../app/appContext';
const GetStarted: React.FC = () => {
  const navigate = useNavigate();
  const {profile, setProfile, uniqueSchoolNames, isphone, setCallback} = useAppContext();
  const [credits, setCredits] = React.useState<string>('');
  const [enrollmentStatus, setEnrollmentStatus] = useState<string | null>(SchoolStatusOptions.enrolled_full_time);
  const [isEnrolled, setIsEnrolled] = useState<boolean>(false);
  const [school, setSchool] = useState<string>('')
  const [schoolOptions, setSchoolOptions] = useState<School[]>([]);
  const [loading, setLoading] = useState(true);

  function navTo() {
   //debugger
    navigate('../about-you/identity');
  }

  const updateProfile = () => {
     profile && school && setProfile((prev) => {
      //console.log(`updating user profile ${JSON.stringify(prev)} with school: ${school} and enrollment status: ${enrollmentStatus} and credits: ${credits}`);
        if (prev) {
          switch (enrollmentStatus) {
            case SchoolStatusOptions.enrolled_full_time:
            case SchoolStatusOptions.enrolled_part_time:
              //console.log('is enrolled activated')
              return {
                ...prev,
                enrolled_at_school:
                  [
                    {
                      school_name: school,
                      enrollment_status: String(enrollmentStatus).toLowerCase() as EnrollmentOptionsType,
                      credits_earned: credits,
                    },
                  ],
                is_primary_school: [{
                  
                  school_name: school,
                  enrollment_status: String(enrollmentStatus).toLowerCase() as SchoolStatusOptionsType,
                  credits_earned: credits
                  
                }]
              };
            case SchoolStatusOptions.alumni:
              return {
                ...prev,
                alumni_of_school: [
                  {
                    school_name: school,
                    enrollment_status: String(enrollmentStatus).toLowerCase() as SchoolStatusOptionsType,
                  }
                ],
                is_primary_school: [{
                  
                  school_name: school,
                  enrollment_status: String(enrollmentStatus).toLowerCase() as SchoolStatusOptionsType,
                  credits_earned: credits
                }],
              }
            default:
              return {
                ...prev,
                is_primary_school: [{
                  school_name: school,
                  enrollment_status: String(enrollmentStatus).toLowerCase() as SchoolStatusOptionsType,
                  credits_earned: credits
                }]
              }
            }
          }
          return prev;
        });
  };

  useEffect(() => {
    //console.log(`enrollment status set to: ${enrollmentStatus}`)
    if (enrollmentStatus !== SchoolStatusOptions.other &&
      enrollmentStatus !== SchoolStatusOptions.alumni &&
      enrollmentStatus !== SchoolStatusOptions.prospective_student) {
      setIsEnrolled(true);
    } else {
      setIsEnrolled(false);
    }
  }, [enrollmentStatus]);

  useEffect(() => {
    setCallback(() => navTo)
  }, []);

  useEffect(()=>{
    schoolOptions && setLoading(false);
  },[schoolOptions])

  return (
      <Root>
         {isphone && <Handshake sx={{ fontSize: 40, color: 'white', marginTop: '50px' }} />}
        <Typography variant={isphone ? "h6" : "h5"} color="white" gutterBottom sx={{ marginTop: '50px' }}>
          Let’s get started!
        </Typography>
        {!isphone && <img src={school_img} alt="school" style={{ maxWidth: '800px', width: '80%', height: 'auto' }} />}
        {!isphone &&  <FreSteps stage={'school'} />}
        <Box sx={{
          padding: '20px',
          maxWidth: '600px',
          color: 'white',
        }}>
          <Typography variant={
            isphone ? "h6" : "h5"
          } color="white" gutterBottom>
            We help connect you with resources to succeed in your college goals.
          </Typography>
          <Spacer />
          <Typography color="white" >
           First choose a school that you would like to learn more about.  It's ok if you don't know yet!  You can choose 'Any' and learn about a variety of schools.
          </Typography>
          <Spacer />

          {(loading || !schoolOptions) ? <CircularProgress /> :
            <div>
              <ChooseSingleOption selectedValue={school || ""} options={uniqueSchoolNames} setSelectedValue={setSchool} label={"Select a school or Any"} />
              <ChooseRadioButtons setSelectedValue={setEnrollmentStatus} options={Object.values(SchoolStatusOptions) || []} label={"What is your enrollment status?"} />
              <Spacer></Spacer>
              {isEnrolled && <ChooseSingleOption selectedValue={credits} options={Object.values(CreditsCompleted)} setSelectedValue={setCredits} label={"How many college credits have you completed?"} />}
            </div>}

        </Box>

        <Spacer />

        <ContinueButton variant="contained" onClick={updateProfile}>
          Continue
        </ContinueButton>

        <Spacer />
      </Root>
  );
};

export default GetStarted;