import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {  Box, CircularProgress, Typography } from '@mui/material';
import HistoryContent from './historyContent';
import { HistoryContainer } from "../app/shared";
import AppStatus from '../../model/conversation/statusMessages';
import AppContainer from '../app/appContainer';
import { useAppContext } from '../app/appContext';

const History: React.FC = () => {
    const {appStatus, history, isphone, getHistoryFromLocalStorage} = useAppContext();
    const navigate = useNavigate();
    console.log('appstatus: ', appStatus)
    console.log(`history: ${JSON.stringify(history)} or ${history.length}`)

    useEffect(()=>{
      getHistoryFromLocalStorage();
    },[])


  return (
 <AppContainer>
      <HistoryContainer isphone={isphone}>
        <Box height='40px'/>
        {(appStatus === AppStatus.GettingMessageHistory) && 
        <CircularProgress/>}
        {appStatus !== AppStatus.GettingMessageHistory && history.length>0 &&
        history?.map((history, index) => (
            <HistoryContent key={index}
            monthLabel={history.current_month || ''}
            topicList={history.topics || ''}
            summary={history.summary || ''}
            resourceList={history.has_pages || []} />
        ))
}

        {(appStatus == AppStatus.Idle && history.length===0 && 
        <Typography color='black'>
          No history exists to display. Check back later after 
          you have asked some questions.
        </Typography>
        )}
      </HistoryContainer>
      
      </AppContainer>
  );
};

export default History;