import React from 'react';
import {  Box,  Typography } from '@mui/material';
import BgPath from '../../assets/images/path_transparent.svg'
import academics from '../../assets/images/Academics.png'
import wellbeing from '../../assets/images/Wellbeing.png'
import finances from '../../assets/images/financial.png'
import career from '../../assets/images/career.png'
import { BackgroundImageContainer, ResourceBgBox, ResourceBox, ResourceLabel } from './shared';
import LandingItems from './landingItems';
import { ResourceList } from '../../api/resources/fetchResourceCategories';
import { LandingObject } from '../../model/nodeTypes';
export interface PositionElements {
    top?: string;
    left?: string;
    imgLink: string;
}

export interface LandingProps{
    resources: ResourceList[];
}

const LandingDesktop: React.FC <LandingProps>= ({resources}) => {
    const bgURL: string = `url(${BgPath})`

    const getPosition = (category: string) => {
        let t = ''
        let l = ''
        let i = ''
        //console.log(`getting position for category ${category}`)
        switch (category) {
            case 'academic':
                t = '100px'
                l = '150px'
                i = `url(${academics})`
                break;
            case 'financial':
                t = '400px'
                l = '70px'
                i = `url(${finances})`
                break;
            case 'career':
                t = '440px'
                l = '360px'
                i = `url(${career})`
                break;
            default:
                t = '160px'
                l = '400px'
                i = `url(${wellbeing})`
                break;
        }

        const pos: PositionElements = {
            top: t,
            left: l,
            imgLink: i
        }
        return pos
    }

    return (
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start'
                }}>
                    <BackgroundImageContainer url={bgURL}>
                        <Box sx={{
                            position: 'absolute',
                            top: '50px',
                            left: '150px'
                        }}><Typography variant="h2">
                                Your Path
                            </Typography>
                        </Box>
                        {Object.entries(LandingObject).map(([category, items], index) => (
    <LandingItems key={index}
        positions={getPosition(category)}
        data={{items: items, category: category}} />
))}
                    </BackgroundImageContainer>
                </Box>
    );
};

export default LandingDesktop;