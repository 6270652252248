import axios from 'axios';
import { BaseUrl } from "../baseURL";
import { UserModel } from '../../model/nodeTypes';
import { handleApiError } from '../apiErrorHandler';
interface fetchProfileParams {
  includeRelationships?: boolean;
}
const fetchProfile = async (
  {includeRelationships=true}: fetchProfileParams = {}
  ):
Promise<UserModel | null> => {
  const apiUrl = `${BaseUrl()}/user-profile`;
  try {

    const requestConfig = {
      url: apiUrl,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      params: {
        include_relationships: includeRelationships
    },
    };
    const response = await axios(requestConfig);
    const userProfile = response.data;
    
    if (userProfile) {
      console.log('Fetched user profile:',JSON.stringify(userProfile));
      sessionStorage.setItem('userProfile', JSON.stringify(userProfile));
      return userProfile;
    } else {
      console.error('Unexpected response format:', response);
      throw new Error('Unexpected response format');
    }
  } catch (error: any) {
   throw handleApiError(error)
}
}


  // Retrieve and parse UserProfile from local storage
export const getUserProfileFromLocalStorage = async (includeRelationships: boolean = true): Promise<UserModel | null> => {

    const storedProfile = sessionStorage.getItem('userProfile');
    if (storedProfile) {
      try {
        const profile: UserModel = JSON.parse(storedProfile) as UserModel;
        if (!profile) {
          const result = await fetchProfile({includeRelationships});
          return result;
        }
        return profile;
      } catch (error) {
        console.error('Failed to parse user profile from local storage:', error);
        return null;
      }
    }
    return null;
  };

export default fetchProfile;
