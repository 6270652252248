import React, { FC } from "react";
import { Typography, Box, Grid } from "@mui/material";
import "../../../../assets/styles.css";
import FaceIcon from '@mui/icons-material/Face';
import { UserChatContainer } from "../../shared";
import {useMediaQuery} from '@mui/system';
import { useAppContext } from "../../../app/appContext";
interface ChatUserChatProps {
  text?: string;
}

const ChatUserChat: FC<ChatUserChatProps> = ({
  text
}) => {

  const {isphone} = useAppContext();
  return (
    <Box id="chatUserContainer" display={'flex'} justifyContent={'flex-end'}>
      <Box id="chatUserContainerSpacer" width={isphone? '80%': '70%'}>
      <Grid container>
        <Grid item xs={11}>
      <UserChatContainer id='user-chat-container'>
          <Typography variant="body1">{text}</Typography>
      </UserChatContainer>
      </Grid>
      <Grid item xs={1}>
        <Box display={'flex'}
         justifyContent={'flex-end'}
          alignItems={'flex-end'}
           width={'100%'}
            height={'100%'}>
      <FaceIcon sx={{ fontSize: 36 }}></FaceIcon>
      </Box>
        </Grid>


      </Grid>
      </Box>
    </Box>
  );
};


export default ChatUserChat;
