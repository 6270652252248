import { handleApiError } from "../apiErrorHandler";
import { BaseUrl } from "../baseURL";
import axios from 'axios';
// api/feedback.ts
export interface FeedbackData {
    object_id: string;
    rating_reasons?: string[];
    comments?: string;
    sentiment: string;
    date_rated: number; 
  }
  
  export const sendFeedback = async (feedbackData: FeedbackData) => {

        const apiUrl = `${BaseUrl()}/feedback`;
        console.log(`sending response feedback with data: ${JSON.stringify(feedbackData)}`)
        //debugger;
        try {
      
          const requestConfig = {
            url: apiUrl,
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              'authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
            },
            data: {
              ...feedbackData
            }
          };


          const response = await axios(requestConfig);
          if (response.status === 200) {
            return ;
          } else {
            console.error('Unexpected response format:', response);
            throw new Error('Unexpected response format');
          }
        } catch (error: any) {
          throw handleApiError(error)
      }
      }
      
      
      export default sendFeedback;
      