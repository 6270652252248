// Redirect from HTTP to HTTPS
if (window.location.protocol === 'http:') {
  window.location.href = window.location.href.replace('http:', 'https:');
}


import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Auth0ProviderWithNavigate from './Auth0ProviderNavigate.js';
import {BrowserRouter} from 'react-router-dom';
import ErrorBoundary from './err-boundary';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
      <ErrorBoundary>
      <Auth0ProviderWithNavigate>
          <App />
      </Auth0ProviderWithNavigate>
      </ErrorBoundary>
    </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
