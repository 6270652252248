import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import fetchMessageHistory from "../../api/chat/fetchMessages";
import ChatInput from "./chatElements/chatInput";
import AppStatus from "../../model/conversation/statusMessages";
import fetchData from "../../api/chat/fetchChatResponse";
import { apiData } from "../../api/chat/fetchChatResponse";
import StarterWindow from "./starterWindow";
import { AnyMessage } from "../../model/nodeTypes";
import ChatMessages from "./chatElements/chatMessageContainer";
import { Spacer } from "../fre/shared";
import ChatTopBar from "./chatTopBar";
import { ChatRootContainer } from "./shared";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../app/appContext";

export const NewChat: React.FC = () => {
    const { appStatus, setAppStatus, setIsError, setErrSev, setErrMessage, setCallback } = useAppContext();
    const [response, setResponse] = React.useState<apiData>();
    const [messages, setMessages] = React.useState<AnyMessage[]>([]);
    const { state } = useLocation();  // Get the state passed from the navigation
    const searchQuery = state?.searchQuery || "";  // Extract the search query if available
    const [searchResults, setSearchResults] = React.useState<AnyMessage[]>([]);
    const [selectedSearchMessage, setSelectedSearchMessage] = React.useState<AnyMessage | undefined>();
    const { user } = useAuth0();
    const [noSearchResults, setNoSearchResults] = React.useState<boolean>(false);
    const [isSearchLoading, setIsSearchLoading] = React.useState<boolean>(false);
    const [term, setTerm] = React.useState<string | null>(null); // Initialize state as null

    useEffect(()=>{
        if (term && appStatus==AppStatus.Idle){
            console.log(appStatus)
            handleSendChat(term);
        }
    },[term, appStatus]);

    const searchCallback = (s: boolean) => {
        setNoSearchResults(s)
        setIsSearchLoading(false)
    }
    useEffect(() => {
        if (state?.term) {
            console.log(`got term from state: ${state?.term}`)
            setTerm(state.term);
        }
    }, [state?.term]); 

    const handleSendChat = async (text: string) => {
        try {
            if (appStatus === AppStatus.Idle) {
                setSelectedSearchMessage(undefined);
                const current_timestamp = Date.now();
                const userMessage: AnyMessage = {
                    role: 'user',
                    user_id: user?.user_id,
                    message_text: text,
                    created_at: current_timestamp
                };
                setMessages([...messages, userMessage]);
                setResponse(await fetchData({
                    messageText: text,
                    setAppStatus,
                    setErrorMessage: setErrMessage
                }));
            }
        } catch (error: any) {
            console.error(error.message);
            setErrMessage(error.message);
            setIsError(true)
            setErrSev('error')
            setAppStatus(AppStatus.Error);
        };
    };


    const handleRetry = async () => {
        try {
            //refresh history in case there is a response?
            if (messages.length > 0) {
                const lastMessage: AnyMessage = messages[messages.length - 1]
                if (lastMessage.role === 'user' && lastMessage.message_text) {
                    handleSendChat(lastMessage.message_text)
                }
            }
        }
        catch (error: any) {
            console.error(error.message);
            setErrMessage(error.message);
            setIsError(true)
            setErrSev('error')
            setAppStatus(AppStatus.Error);
        }
    }

    const getHistory = async (query?: string) => {
        try {
            //console.log(`fetching history with query: ${query} or null `);
            setSelectedSearchMessage(undefined);
            const result: AnyMessage[] = await fetchMessageHistory({ callback: searchCallback, searchTerm: query });
            //console.log(`fetching history with messages: ${JSON.stringify(result)}`);
            if (query && query !== '') {
                setSearchResults(result);
            }
            else {
                setMessages(result);
            }
            setAppStatus(AppStatus.Idle);
        } catch (error: any) {
            console.error(error.message);
            setErrMessage(error.message);
            setIsError(true)
            setErrSev('error')
            setAppStatus(AppStatus.Error);
        }
    };

    useEffect(() => {
        if (searchQuery) {
            getHistory(searchQuery);  // Automatically search for the keyword if loaded from history page
        }
    }, [searchQuery]);

    useEffect(() => {
        if (response) {
            //console.log(`received response: ${JSON.stringify(response)}`);
            const updatedMessages = [...messages];
            const messageLength = updatedMessages.length - 1

            //set object id of the last message in the list from the response
            if (updatedMessages[messageLength].role === 'user') {
                updatedMessages[updatedMessages.length - 1] = {
                    ...updatedMessages[messageLength],
                    object_id: response.rag_response?.user_object_id
                };
            }

            const combinedResponse: string = (
                response.rag_response?.response || '' + response.kickback_response?.follow_up_question || ''
            )

            //add bot message to message list
            const botQuestion: AnyMessage = {
                user_id: user?.user_id,
                role: "system",
                message_text: combinedResponse,
                created_at: Date.now(),
                object_id: response.rag_response?.bot_object_id
            };

            setMessages([...updatedMessages, botQuestion]);
            setResponse(undefined);
            setTerm('')
            setAppStatus(AppStatus.Idle)
        }
    }, [response]);


    useEffect(() => {
        setCallback(() => {});
        setAppStatus(AppStatus.GettingMessageHistory)
        getHistory();
    }, []);

    return (
        <ChatRootContainer id="new-chat-container">
            {appStatus === AppStatus.GettingMessageHistory && (
                <CircularProgress></CircularProgress>
            )}
            {!messages.length && appStatus === AppStatus.Idle && (
                <StarterWindow
                    handleButtonClick={handleSendChat} />
            )}
            {messages.length > 0 &&
                <>
                    <ChatTopBar
                        searchResults={searchResults}
                        setSearchResults={setSearchResults}
                        fetchMessageHistory={getHistory}
                        setSelectedSearchResult={setSelectedSearchMessage}
                        isSearchLoading={isSearchLoading}
                        setIsSearchLoading={setIsSearchLoading}
                        searchInput={searchQuery}  // Pass the search text to the top bar
                        noSearchResults={noSearchResults}
                        setNoSearchResults={setNoSearchResults}
                    />
                    <ChatMessages
                        messageHistory={messages}
                        onRetry={handleRetry}
                        searchMessage={selectedSearchMessage}
                    />
                </>
            }
            <ChatInput sendChat={handleSendChat} />
            <Spacer />
        </ChatRootContainer>
    );
};

export default NewChat;
