import React, { useEffect } from "react";
import { Box, Typography, Tooltip, CircularProgress, IconButton, Modal, TextField } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { ProfileContentContainer, ProfileAccountContainer, ProfileHorizontalPanel, ProfileMenu } from "./shared";
import ProfileUserForm from "./profileUserForm";
import ProfileInterests from "./profileInterests";
import fetchProfile from "../../api/user/fetchProfile";
import setProfile from "../../api/user/setProfile";
import { School, UserModel } from "../../model/nodeTypes";
import { useAuth0 } from "@auth0/auth0-react";
import { DefaultButton } from "../app/shared";
import ProfileDeleteModal from "./profileDeleteModal";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useAppContext } from "../app/appContext";

const ProfileContent: React.FC = () => {
  const { user } = useAuth0();
  const {profile, setProfile, isphone} = useAppContext();
  const [isEditing, setIsEditing] = React.useState(false);
  const [deleteClick, setDeleteClick] = React.useState(false);

  const handleEditProfile = () => {
    setIsEditing(!isEditing);
  };

  const openDelete = () => {
    setDeleteClick(true);
  }



  return <ProfileContentContainer>
    {profile && <ProfileDeleteModal deleteClick={deleteClick} setDeleteClick={setDeleteClick} user={profile}/>}
    <ProfileAccountContainer >
      <Box id="profile-pic-container" position='relative'>
        {(user && user.picture) ? <img src={user.picture} alt={user.name} /> :
          <AccountCircleIcon sx={{ fontSize: '100px', color: '#0097b2' }} />}
          
          <IconButton onClick={openDelete} sx={{
            position: 'absolute',
            color: 'black',
            top: '0',
            right: '-20px',
          }}>
          <Tooltip title='Delete Profile'>
          <DeleteForeverIcon/>
          </Tooltip>
          </IconButton>

      </Box>
    </ProfileAccountContainer>
    {profile ?
      <Box>
        <ProfileHorizontalPanel id='profile-horizontal-panel2'>
          <ProfileMenu id='profile-menu-1'>
            <Tooltip title={!isEditing ? "Edit Profile" : "Cancel Edit"}>
              <IconButton onClick={handleEditProfile}>
                {!isEditing ? <EditIcon sx={{ fontSize: '20px', color: '#0097b2' }} /> :
                  <CancelIcon sx={{ fontSize: '20px', color: '#0097b2' }} />}
              </IconButton>
            </Tooltip>
          </ProfileMenu>
          <ProfileUserForm 
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </ProfileHorizontalPanel>
        <ProfileHorizontalPanel id='profile-horizontal-panel3'>
          <ProfileInterests/>
        </ProfileHorizontalPanel>
      </Box> : <CircularProgress />}
  </ProfileContentContainer>
};

export default ProfileContent;