import axios from 'axios';
import { BaseUrl } from "../baseURL";
import parseStackTrace, { StackTraceParse } from '../parseStack';
import { handleApiError } from '../apiErrorHandler';
import { Bookmarks } from '../resources/fetchResources';
import AppStatus from '../../model/conversation/statusMessages';
import { Apps } from '@mui/icons-material';
export interface HistoryItems {
    created_at?: string;
    updated_at?: string;
    current_month?: string;
    month_numeric?: number;
    object_id?: string;
    current_year?: number;
    topics?: string;
    summary?: string;
    resources?: string[];
    sortable_id?: number;
    has_pages?: Bookmarks[];
}

export interface HistoryRefresh {
  history_items: HistoryItems[];
  refresh_time: Date
}

interface FetchHistoryProps {
  setAppStatus: React.Dispatch<React.SetStateAction<AppStatus>>;
  setHistory: React.Dispatch<React.SetStateAction<HistoryItems[]>>;
}

const FetchHistory = async ({setAppStatus, setHistory}: FetchHistoryProps): Promise<HistoryItems[]> => {
  const apiUrl = `${BaseUrl()}/history`;
  console.log(`fetching history`);
  
  try {
    const requestConfig = {
      url: apiUrl,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
    };

    const response = await axios(requestConfig);
    const response_items: HistoryItems[] = response.data
    //console.log(`got history from API: ${JSON.stringify(response_items)}`)
    if (response.status === 204) {
      console.log('No history data available.');
      setAppStatus(AppStatus.Idle)
     return []
    }
    const history_refresh: HistoryRefresh = {history_items: response_items, refresh_time: new Date()}

    sessionStorage.setItem('userHistory', JSON.stringify(history_refresh));
    setAppStatus(AppStatus.Idle)
    setHistory(response_items)
    return response_items;

  } catch (error: any) {
    throw handleApiError(error)
}
}


export default FetchHistory;