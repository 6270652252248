import react, { useEffect, useState, useRef } from 'react';
import { Button, Box, Typography, Tooltip, Select, MenuItem } from '@mui/material';
import { UserModel } from '../../model/nodeTypes';
import { IdentityBox, InterestItem, InterestItemText } from './shared';
import hat_small from '../../assets/images/hat_small.png';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { ProfileAddInterestButton } from './shared';
import { IdentityOptionsType, IdentityOptions } from '../../model/nodeTypes';
import { useAppContext } from '../app/appContext';

interface ProfileButtonProps {
    label: string;
    onClick: () => void;
}

const ProfileButton: React.FC<ProfileButtonProps> = ({ label, onClick }) => {

    return (
        <InterestItem>
            <InterestItemText>
                <Tooltip title="remove">
                    <Button onClick={onClick} endIcon={<CloseIcon />}>
                        <Typography>{label}</Typography>
                    </Button>
                </Tooltip>
            </InterestItemText>
        </InterestItem>
    )
};

const ProfileInterests: React.FC = () => {
    const [addVisible, setAddVisible] = useState<boolean>(false);
    const [selectedFactor, setSelectedFactor] = useState<string>(''); // Track selected dropdown value
    const { profile, setProfile } = useAppContext();
    const [identityFactors, setIdentityFactors] = useState<string[]>(profile?.identified_as?.map((factor) => factor.identity_name) || []);
    const identityFactorsRef = useRef<string[]>(identityFactors);
    const handleSelectFactor = (event: any) => {
        const selected = event.target.value;
        setSelectedFactor(selected);
        if (!identityFactors.includes(selected)) {
            setIdentityFactors((prev) => [...prev, selected]); // Add the selected identity factor
        }
        setAddVisible(false); // Hide the dropdown after selecting a factor
    };

    const removeItem = (index: number) => {
        // Create a new array without the removed item
        const updatedFactors = identityFactors.filter((_, i) => i !== index);
        // Update the state with the new array
        setIdentityFactors(updatedFactors);
    };

    const handleAdd = () => {
        setAddVisible(true)
    }

    const availableFactors: IdentityOptionsType[] = Object.values(IdentityOptions).filter(
        (option) => !identityFactors.includes(option)
    );

    const updateProfile = async () => {
        if (identityFactors && identityFactors.length > 0) {
            try {
                // Update the user profile state
                setProfile((prev) => {
                    if (prev) {
                        return {
                            ...prev,
                            identified_as: identityFactors.map((option) => ({
                                identity_name: option,
                            })),
                        };
                    }
                    return prev;
                });

            } catch (error) {
                console.error('Error sending school:', error);
            }
        }
    };

    useEffect(() => {
        if (identityFactors !== identityFactorsRef.current) {
            identityFactorsRef.current = identityFactors;
            updateProfile();
        }
    }, [identityFactors]);


    return (
        <Box>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'left'} alignItems={'flex-end'}>
                <img src={hat_small} alt="hat" width="auto" height="40px" />
                <Typography variant="h4" sx={{ marginBottom: '10px', marginLeft: '10px', textAlign: 'left' }}>Things I know about you</Typography>
            </Box>

            <IdentityBox>
                {identityFactors.map((factor: string, index: number) => (
                    <ProfileButton
                        key={index}  // Add a key prop when rendering lists
                        label={factor}
                        onClick={() => removeItem(index)}  // Pass the index to removeItem
                    />
                ))}{

                    !addVisible ?
                        <ProfileAddInterestButton variant="contained"
                            endIcon={<AddIcon />}
                            onClick={handleAdd}>...add more
                        </ProfileAddInterestButton> :
                        <Box mt={2}>
                            <Select value={selectedFactor} onChange={handleSelectFactor} displayEmpty>
                                <MenuItem value="" disabled>
                                    Select an identity factor
                                </MenuItem>
                                {availableFactors.map((factor, index) => (
                                    <MenuItem key={index} value={factor}>
                                        {factor}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                }
            </IdentityBox>

        </Box >
    );
};

export default ProfileInterests;