import React from 'react';

import {  Box } from '@mui/material';
import academics from '../../assets/images/Academics.png'
import wellbeing from '../../assets/images/Wellbeing.png'
import finances from '../../assets/images/financial.png'
import career from '../../assets/images/career.png'
import { ResourceList } from '../../api/resources/fetchResourceCategories';
import { LandingObject } from '../../model/nodeTypes';
import LandingItems from './landingItems';
import { PositionElements } from './landingDesktop';
import { LandingProps } from './landingDesktop';
import PlaceIcon from '@mui/icons-material/Place';
import VerticalDashedLine from './dashedSVG';
import { useAppContext } from '../app/appContext';

const LandingMobile: React.FC <LandingProps>= ({resources}) => {
    const {istinyphone} = useAppContext();

    const getPosition = (category: string) => {
        let i = ''
        switch (category) {
            case 'academic':
                i = `url(${academics})`
                break;
            case 'financial':
                i = `url(${finances})`
                break;
            case 'career':
                i = `url(${career})`
                break;
            default:
                i = `url(${wellbeing})`
                break;
        }

        const pos: PositionElements = {
            imgLink: i
        }
        return pos
    }

    return (
     
                <Box id='resources-box' sx={{
                    display: 'flex',
                    with: '300px',
                    margin: '20px',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItem: 'flex-end',
                    
                }}>
                {Object.entries(LandingObject).map(([category, items], index) => (
    <LandingItems key={index}
        positions={getPosition(category)}
        data={{items: items, category: category}} />
))}
                </Box>
    );
};

export default LandingMobile;