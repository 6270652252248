import Reactm from 'react';
import { forwardRef } from 'react';
import { Box, BoxProps, Button, TextField} from '@mui/material';
import { styled, useMediaQuery } from '@mui/system';
import { useTheme, Theme } from '@mui/material';
import ExtendedBox from '../extendedComponent';
export const ChatRootContainer = styled(Box)({
    display: 'flex',
    flexDirection:'column',
    height:'100%',
    width:'100%',
    alignContent:'center',
    alignItems:'center',
    justifyItems:'center',
    justifyContent:'center',
});

export const ChatChatContainer = styled(Box)({
  alignItems: "center", 
  width: "95%", p: 2,
  display: "flex",
  flexGrow: 1,
  height: "100%",
  flexDirection: "column",
  mt: 2,
  borderRadius: 1, 
  justifyContent: "space-between"
});

export const ChatMessageHistoryContainer = styled(Box)({
  display: "flex",
    flexDirection: 'column',
    height: "90%",
    width: "100%",
    overflowY: "auto",
    marginTop: "20px",
    justifyContent: 'flex-end',
});

export const ChatMessagesList = styled(Box)({
    display: 'flex',
    overflowY: "auto",
    marginTop: "20px",
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
});

export const ChatBotResponseContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  marginRight: '10px',
  marginLeft: '5px',
  minWidth: '100px',
  borderRadius: '30px',
  border: '1px solid theme.palette.secondaßry.light',
  boxShadow: '0px 2px 2px rgba(0,0,0,.4)',
  backgroundColor: 'rgb(178, 27, 0, 0.1)',
});

export const ChatBotResponseIconsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'row',
  width: '100%',
  marginLeft: '20px',
  marginTop: '10px'
});

export const UserChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  marginRight: '10px',
  maxWidth: '100%',
  //minWidth: '300px',
  borderRadius: '30px',
  border: `${theme.palette.secondary.light}`,  // Use theme here
  backgroundColor: 'white ',
}))

export const TopBar = styled(Box)({
    width: '90%',
    marginTop: '10px',
    height: '60px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent:'space-between',
    padding: '0px',
    textAlign: 'left',
    borderRadius: '50px',
  });

  export const SearchBar = styled(ExtendedBox)(({ isselected = false, isphone=false }) => ({
  width: isphone? '220px': '290px',
  top: '0px',
  height: isselected? '500px' : '50px',
  background: 'white',
  justifyContent: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: !isselected? '50px' : '10px',
  border: '1px solid rgb(0,0,0,.2)',
  
  }));
  
  export const SearchDivider = styled('hr')({
    width: '100%',
    margin: '0px',
    borderTop: '1px solid rgb(0,0,0,.2)',
  });

  export const SuggestedQuestionsContainer = styled(Box)({
    width: '250px',
    height: '300px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
    maxHeight: '600px',
    position: 'absolute',
    overflowY: 'auto',
    bottom: '50px',
    right: '0px',
    justifyContent: 'flex-start',
    borderRadius: '10px',
    border: '1px solid rgb(0,0,0,.2)',
  })
  
  export const QuestionItemContainer = styled(Box)({
    padding: '12px',
    cursor: 'pointer',
    alignItems: 'center',
  })
  
  export const RecentSearchOptions = styled(Box)({
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      gap: 2, // Add spacing between buttons
      justifyContent: 'flex-start', // Align buttons to the start
      padding: 2, // Optional: Add padding around the Box
  });
  
  export const RecentTopicButton = styled(Button)({
    variant: 'contained',
    margin: '10px',
    backgroundColor: 'white',
  
  });

  export const XButton = styled(Button)({
    backgroundColor:  '#a6a6a6',
    width: '22px',
    minWidth: '22px',
     height: '22px', 
     padding: '0px', 
     margin: '0px',
     borderRadius: '50%'

  });

  export const SearchTextField = styled(TextField)({
    width: '300px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      '& fieldset': {
        borderColor: 'rgb(0,0,0,0)', 
      },
      '&:hover fieldset': {
        borderColor: 'rgb(0,0,0,0)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgb(0,0,0,0)', 
      },
    },
  });

  export const ChatInputField = styled(TextField)({
    width: '100%',
    position: 'relative',
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.85)', 
        borderWidth: '1px',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.85)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.85)', 
      },
    },
  });

export const ReviewBoxContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '300px',
  height: '400px',
  padding: '10px',
  borderRadius: '10px',
  backgroundColor: 'white',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
});

export const ReviewBoxHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '10px',
  marginBottom: '10px',
});