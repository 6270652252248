import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Root, LeftNav, Panel, RootRight } from './shared';
import { useMediaQuery, Box, Button, Typography } from '@mui/material';
import { mediaSize, gridSize } from '../fre/shared';
import { SideMenu } from './leftNav';
import NewChat from '../chat/newChat';
import PersistentDrawer from './navDrawer';
import LogoutButton from './logout';
import { useAppContext } from './appContext';
// Main PersistentDrawer component
interface AppContainerProps {
  children: React.ReactNode;
}


const AppContainer: React.FC<AppContainerProps> = ({ children }) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>("");
  const {isphone} = useAppContext();


  return (

    <Root>
      <LogoutButton />
      {isphone ?
        <PersistentDrawer selectedText={selectedMenuItem} setSelectedText={setSelectedMenuItem}>
          <Panel id='panel' isphone={isphone} >
            {children}
          </Panel>
        </PersistentDrawer> :
        <>
          <RootRight />
          <LeftNav>
            <SideMenu selectedText={selectedMenuItem} setSelectedText={setSelectedMenuItem} />
          </LeftNav>
          <Panel id='panel' isphone={isphone} >
            {children}
          </Panel>
        </>
      }
    </Root>

  );
};

export default AppContainer;