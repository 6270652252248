import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { School, Handshake, Chat } from '@mui/icons-material'; // Import MUI icons
import { styled, useMediaQuery } from '@mui/system';

export const Root = styled(Box)({
  width: '100%',
  height: '100%',
  backgroundColor: '#00697c',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  padding: '0px',
  textAlign: 'center',
  margin: '0px',
  minHeight: '100vh',
});

export const TosContainer = styled(Box)({
  backgroundColor: '#F2F2F2',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  overflowY: 'auto',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  textAlign: 'left',
  margin: '10px',
});

export const TosHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px',
  marginBottom: '20px',
  backgroundColor: '#00697c',
  width: '100%',
  height: '50px',
});

export const Panel = styled(Box)({
  width: '80%',
  height: '80%',
  backgroundColor: '#F2F2F2',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',

  padding: '20px',
  textAlign: 'center',
  margin: '10px',
});

export const StepContainer = styled(Box)({
  display: 'flex',
  marginTop: '0px',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '80%',
  maxWidth: '800px',
  marginBottom: '40px',
});

export const Step = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  width: '30%',
});

export const ContinueButton = styled(Button)({
  backgroundColor: '#FFDD57',
  color: 'black',
  minWidth: '200px',
  padding: '12px 24px',
  borderRadius: '50px',
  '&:hover': {
    backgroundColor: '#FFC107',
  },
});

export const Spacer = styled('div')({
  height: '50px',
});

export const Line = styled('hr')({
  width: '80px',
  border: 'none',
  borderTop: '5px solid #a6a6a6',
  margin: '0 5px',
});

export interface mediaSizeProps {
    isphone?: boolean;
    istablet?: boolean;
  }

  // Function to determine the media size
  export const mediaSize = ({isphone, istablet}: mediaSizeProps): string => {
    if (istablet) {
      if (isphone) {
        return 'phone';
      }
    return 'tablet'
    };
    return 'desktop';
  };

  export const gridSize = ({isphone, istablet}: mediaSizeProps): number=> {
    if (istablet) {
      if (isphone) {
        return 12;
      }
    return 6
    };
    return 4;
  };
