import React, { useState, useRef, useEffect } from "react";
import { Typography, Button, Box } from "@mui/material";
import { TimelineItem, TimelineItemContent, TimelineItemRow, TimelineLabelContainer } from "../app/shared";
import LanguageIcon from '@mui/icons-material/Language';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from '@mui/system';
import { Bookmarks } from "../../api/resources/fetchResources";
import { useAppContext } from "../app/appContext";

interface HistoryContentProps {
    monthLabel: string;
    topicList: string;
    summary: string;
    resourceList: Bookmarks[];
}

const HistoryContent: React.FC<HistoryContentProps> = ({ monthLabel, topicList, summary, resourceList }) => {
    const navigate = useNavigate();
    const {isphone}= useAppContext();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const resourceContainerRef = useRef<HTMLDivElement | null>(null);

    const handleTopicClick = (topic: string) => {
        navigate('../chat', { state: { searchQuery: topic } });
    };

    const openLinkInNewTab = (url: string): void => {
        window.open(url, '_blank');
    };

    useEffect(() => {
        if (resourceContainerRef.current) {
            setIsOverflowing(resourceContainerRef.current.scrollHeight > resourceContainerRef.current.clientHeight);
        }
    }, [resourceList]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const topics = topicList.split(',').map(topic => topic.trim());

    return (
        <div>
            <TimelineItem id='timeline-item' isphone={isphone}>
                <TimelineLabelContainer id='timeline-label-container' isphone={isphone}>
                    <Typography sx={{ fontWeight: '400' }}>{monthLabel}</Typography>
                </TimelineLabelContainer>
                <TimelineItemContent id='timeline-content' isphone={isphone}>
                    <TimelineItemRow isphone={isphone} id='timeline-item-row'>
                        <Box sx={{ fontWeight: '500', width: '120px', display: 'flex' }}>
                            <Typography sx={{ fontWeight: '500', width: '120px', textAlign: 'left' }}>Topics:&nbsp;&nbsp;</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} flexWrap='wrap'>
                            {Array.isArray(topics) && topics.map((topic, index) => (
                                <Box key={index} display={'flex'} flexDirection={'row'} flexWrap="wrap">
                                    <Typography
                                        sx={{
                                            fontWeight: '300',
                                            cursor: 'pointer',
                                            color: 'blue',
                                            textDecoration: 'underline',
                                            whiteSpace: 'nowrap'
                                        }}
                                        onClick={() => handleTopicClick(topic)}
                                    >
                                        {topic}
                                        {index < topicList.length - 1 && ', '}
                                    </Typography>
                                    <span>&nbsp;&nbsp;</span>
                                </Box>
                            ))}
                        </Box>
                    </TimelineItemRow>
                    <TimelineItemRow isphone={isphone}>
                        <Box sx={{ fontWeight: '500', width: '120px' }}>
                            <Typography sx={{ fontWeight: '500', width: '120px', textAlign: 'left' }}>Summary:&nbsp;&nbsp;</Typography>
                        </Box>
                        <Typography textAlign='left' sx={{ fontWeight: '300', marginRight: '10px' }}>{summary}</Typography>
                    </TimelineItemRow>
                    <TimelineItemRow isphone={isphone}>
                        <Box sx={{
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center'
                        }}><LanguageIcon />&nbsp;&nbsp;</Box>
                        <Box
                            ref={resourceContainerRef}
                            sx={{
                                maxHeight: isExpanded ? 'none' : '60px', // Adjust the height to show limited lines
                                overflow: 'hidden',
                                display: 'flex',
                                flexWrap: 'wrap',
                                transition: 'max-height 0.3s ease'
                            }}
                        >
                            {resourceList && resourceList.map((resource, index) => (
                             !resource.is_invalid &&
                                <Button
                                    key={index}
                                    onClick={() => openLinkInNewTab(resource.page_url)}
                                    sx={{
                                        wordBreak: 'break-all',
                                        whiteSpace: 'normal',
                                        backgroundColor: '#f6f6f6',
                                        margin: '10px',
                                        border: '1px solid rgb(0,0,0,.2)'
                                    }}
                                >
                                    {resource.page_friendly_name}
                                </Button>
                            ))}
                        </Box>
                    </TimelineItemRow>
                    {isOverflowing && (
                        <Box textAlign="right">
                            <Button onClick={toggleExpand}>
                                {isExpanded ? 'Show Less' : 'Show More'}
                            </Button>
                        </Box>
                    )}
                </TimelineItemContent>
            </TimelineItem>
        </div>
    );
};

export default HistoryContent;