import { Box, CircularProgress, Typography } from "@mui/material";
import React, { FC, useEffect, useRef } from "react";
import "../../../assets/styles.css";
import messageSample from "../../../model/messages/messageSample.json";
import { Timestamp } from "../../../model/messages/messages";
import ChatMessageHistory from "./chatMessageElements/chatMessageHistory";
import { AnyMessage } from "../../../model/nodeTypes";
import AppStatus from "../../../model/conversation/statusMessages";
import ChatBotChatError from "./chatMessageElements/chatResponse/chatBotChatError";
import { useTheme } from "@emotion/react";
import { ChatMessageHistoryContainer, ChatMessagesList } from "../shared";
import { useAppContext } from "../../app/appContext";
//for default props
const jsonString = JSON.stringify(messageSample);
const AUTH_TYPE = process.env.REACT_APP_AUTH_TYPE || 'NONE';
/**
 * Props for the ChatMessages component.
 */
interface ChatMessagesProps {
  searchMessage?: AnyMessage;
  onRetry?: () => void;
  messageHistory?: AnyMessage[];

}

function getCurrentTimestamp(): Timestamp {
  const theme = useTheme();
  return {
    $date: Date.now() // Current time in milliseconds
  };
}

const ChatMessages: FC<ChatMessagesProps> = ({
  onRetry,
  messageHistory,
  searchMessage,
}) => {
  const [passedRef, setPassedRef] = React.useState<HTMLDivElement | null>(null);
  const {appStatus} = useAppContext();
  useEffect(() => {
    if (passedRef) {
      // Wait for the rendering to complete before triggering the scroll
      setTimeout(() => {
        console.log(`scrolling to bottom of chat history - Tag: ${passedRef.tagName}`);
        passedRef.scrollIntoView({
          behavior: 'smooth',
          block: passedRef.id === 'selected-search-ref' ? 'center' : 'end'
        });
      }, 100); // You can tweak this delay based on performance, start with 100ms
    }
  }, [passedRef, appStatus, messageHistory]);

  // //console.log(`testing app status: ${appStatus}`)
  return (
    <ChatMessageHistoryContainer id='chat-message-history-container'>
      {messageHistory && appStatus !== AppStatus.GettingMessageHistory &&
        <ChatMessageHistory 
          messages={messageHistory}
          onRetry={onRetry}
          passRef={setPassedRef}
          selectedMessage={searchMessage}
        />}
    </ChatMessageHistoryContainer>
  );
};


export default ChatMessages;
