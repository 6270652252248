import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { School, Handshake, Chat, Preview } from '@mui/icons-material'; // Import MUI icons
import { useMediaQuery } from '@mui/system';
import { ContinueButton, Step, StepContainer, Root, Line, Spacer, mediaSize, gridSize } from './shared';
import FreSteps from './base';
import { useNavigate } from 'react-router-dom';
import handshake from '../../assets/images/handshake.png';
import { getUserProfileFromLocalStorage } from '../../api/user/fetchProfile';
import { UserModel, IdentityOptions} from '../../model/nodeTypes';
import setProfile from '../../api/user/setProfile';
import { useAppContext } from '../app/appContext';

const GetHelp: React.FC = () => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const navigate = useNavigate();
  const {isphone,setCallback, profile, setProfile } = useAppContext();

  function navTo() {
    navigate('../about-you/progress');
  }

  
 useEffect(() => {
  setCallback(() => navTo)
 }, []);

 const updateProfile = async () => {
  if (selectedOptions && selectedOptions.length > 0) {
    try {
    // Update the user profile state
   setProfile((prev) => {
      if (prev) {
        return {
          ...prev,
          identified_as: selectedOptions.map((option) => ({
            identity_name: option,
          })),
        };
      }
      return prev;
    });
      
    } catch (error) {
      console.error('Error sending school:', error);
    }
  }
};



  const handleButtonClick = (option: string) => {
    setSelectedOptions((prev) =>
      prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
    );
  };

  return (
    <Root>
      {isphone && <Handshake sx={{ fontSize: 40, color: 'white', marginTop: '50px' }} />}
      {!isphone && <img src={handshake} alt="school" style={{ maxWidth: '800px', width: '80%', height: 'auto' }} />}
      {!isphone && <FreSteps stage={'identity'} />}

      <Typography variant={
        isphone ? "h6" : "h5"
      } color="white" sx={{ maxWidth: '75%' }} gutterBottom>
        Which of the following describes you? Select all that apply:
      </Typography>
      <Spacer />
      <Grid container spacing={2} sx={{ maxWidth: '80%' }}>
        {Object.values(IdentityOptions).map((option) => (
          <Grid item xs={isphone? 10 : 4} key={option} sx={{ marginBottom: '10px' }}>
            <Button
              variant="contained"
              onClick={() => handleButtonClick(option)}
              sx={{
                width: '100%',
                textAlign: 'center',
                backgroundColor: selectedOptions.includes(option) ? '#FFDD57' : '#a6a6a6',
                color: 'black',
                '&:hover': {
                  backgroundColor: selectedOptions.includes(option) ? '#FFC107' : '#a6a6a6',
                },
              }}
            >
              {option}
            </Button>
          </Grid>
        ))}
      </Grid>

      <Spacer />
      <ContinueButton variant="contained" onClick={updateProfile}>
        Continue
      </ContinueButton>
      <Spacer />
    </Root>
  );
};


export default GetHelp;