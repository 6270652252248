
import axios from 'axios';
import { AlertColor } from '@mui/material';
import { StackTraceParse } from './parseStack';
import parseStackTrace from './parseStack';
export class MUIError extends Error {
  severityLevel?: AlertColor;
  redirectLink?: string;
  constructor(message: string = 'An error occurred', severityLevel: AlertColor = 'error', redirectLink: string ='') {
    super(message); // Call the parent constructor (Error) with the message
    this.name = this.constructor.name || ''; // Set the error name to the class name
    this.severityLevel = severityLevel; // Add custom severity level
    this.redirectLink = redirectLink;
    Error.captureStackTrace(this, this.constructor); // Capture the stack trace
  }
}

export function handleApiError(error: any): Error {
  if (error instanceof Error) {
    const errTrace: StackTraceParse = parseStackTrace(error);

    if (axios.isAxiosError(error)) {
      //debugger;
      let passErr = new MUIError(); 
      console.error('API error:', error.response?.status, error.response?.data);
      switch (error.response?.status) {
        case 401:
          passErr = new MUIError(
            'User is not authorized to access this endpoint. If you believe you are authorized, please login again.',
            'error',
            '/'
          );
          return passErr
        case 404:
            passErr = new MUIError(
              'User is not authorized to access this endpoint. If you believe you are authorized, please login again.',
              'error',
              '/'
            );
            return passErr
        case 570:
          passErr = new MUIError(
            'Bookmarks can only be added to messages that contain links to external resources.',
            'warning',
          );
          return passErr
          case 571:
            passErr = new MUIError(
              'failed to generate new bookmarks from message.',
              'error',
            );
            return passErr
            case 572:
              passErr = new MUIError(
                'Unable to return bookmarks: Failed to serialize node.',
                'error',
              );
              return passErr
              case 573:
                passErr = new MUIError(
                  'Unable to return bookmarks by category: Failed to serialize node.',
                  'error',
                );
                return passErr
                case 574:
                  passErr = new MUIError(
                    'Failed to delete bookmark.  Bookmark still exists!',
                    'error',
                  );
                  return passErr
                
        case 530:
          passErr = new MUIError(
            'Failed to fetch or save user details.  Please login again.',
            'error',
            '/'
          );
          return passErr
          case 531:
            passErr = new MUIError(
              'Failed to save user details. User profile is not updated with changes.  Please contact support@campusevolve.ai',
              'error',
            );
          return passErr
          case 531:
            passErr = new MUIError(
              'unable to get schools from server',
              'error',
              '../'
            );
          return passErr
          case 540:
            passErr = new MUIError(
              'Failed to process chat messsage.  Please try again',
              'error',
            );
            return passErr
          case 541:
              passErr = new MUIError(
                'Failed to save chat messsage.  Please try again',
                'error',
              );
              return passErr
          case 542:
                passErr = new MUIError(
                  'Chat message saved, but there was an error connecting the message to your profile. Bookmarks will not be available for this message.  If this continues to happen, please email us at support@campusevolve.ai',
                  'error',
                  '../'
                );
                return passErr
          case 543:
                  passErr = new MUIError(
                    'There was an error getting message history from the server.  When you close this message, '+
                    'we will automatically retry. If this error keeps happening, contact us at support@campusevolve.ai',
                    'error',
                    '../'
                  );
                  return passErr
          case 544:
            passErr = new MUIError(
              'History failed to refresh.  The system will automatically try again when the page reloads. If this error keeps happening, contact us at support@campusevolve.ai',
              'info',
            );
            return passErr
        default:
          
          passErr = new MUIError(
            'Oops - the server threw an error! Please try your action again. If this error keeps happening, contact us at support@campusevolve.ai',
            'error'
          );
      }
      return passErr
    } else {
      if (error.name === 'noResponse' || error.name === 'unexpectedFormat') {
        return new MUIError(error.message, 'error');
      } else {
        console.error('Error:', error.message);
        const message = `API returned an unrecognized error.  
        ${errTrace.functionLine} ${errTrace.callerLine}`;
        const unexp = new MUIError(message);
        unexp.name = errTrace.errName;
        return unexp;
      }
    }
  }

  return new MUIError(error); // Default throw if the error is not an instance of Error
}