import axios, { AxiosRequestConfig } from 'axios';
import { handleApiError } from '../apiErrorHandler';
import { BaseUrl } from '../baseURL';


// API function to delete a Profile
export async function deleteProfile(): Promise<void> {
    const apiUrl = `${BaseUrl()}/delete-user`;
    console.log('starting delete')
   //debugger
// Define the request config constant
const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
    },
  };
  
  try {
    const response = await axios.delete(apiUrl, config);
    console.log('Profile deleted successfully:', response.data);
  } 
 catch (error: any) {
  throw handleApiError(error)
}
}


