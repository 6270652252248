import axios from 'axios';
import { BaseUrl } from "../baseURL";
import { Bookmarks } from './fetchResources';
import { handleApiError } from '../apiErrorHandler';

export interface ResourceList {
  category: string; 
  pages: Bookmarks[];
}

const FetchResourceCategories = async (): Promise<ResourceList[] | null> => {
  const apiUrl = `${BaseUrl()}/bookmark-categories`;
  const requiredCategories = ['financial', 'academic', 'career', 'wellbeing']; 

  console.log(`fetching resources`);
  
  try {
    const requestConfig = {
      url: apiUrl,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
    };

    const response = await axios(requestConfig);

    if (response.status === 204) {
      console.log('No resources data available.');
      return null;
    }
    if (response.data && Array.isArray(response.data)) {
      console.log(`Got response from resources API: ${JSON.stringify(response.data)}`);
      // Create a map of existing categories from the response
      const existingCategories = response.data.reduce((acc: any, item: ResourceList) => {
        acc[item.category] = item;
        return acc;
      }, {});

      // Loop through the required categories and add missing ones
      const finalCategories = requiredCategories.map((category) => {
        if (existingCategories[category]) {
          return existingCategories[category];
        } else {
          // Add missing category with an empty pages list
          return { category, pages: [] };
        }
      });

    return finalCategories; // Return the final array with all required categories
}
    throw new Error('unable to parse response data')
  } catch (error: any) {
    throw handleApiError(error)
}
}


export default FetchResourceCategories;