import axios from 'axios';
import { handleApiError } from '../apiErrorHandler';
import { BaseUrl } from "../baseURL";
import { AnyMessage } from '../../model/nodeTypes';

export interface fetchMessageHistoryParams {
  callback?: (val: boolean) => void
  searchTerm?: string
}
const fetchMessageHistory = async ({callback, searchTerm}:fetchMessageHistoryParams):Promise<AnyMessage[]>=> {
  const apiUrl = `${BaseUrl()}/messages`;

  const requestConfig = {
      url: apiUrl,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      params: {
        query: searchTerm || '',
      }
    };
  // console.log(`fetching messages for conversationId: ${JSON.stringify(conversationId)}`)

  try {
      // Making the axios call and expecting the response to be of type ApiMessage
      const response = await axios(requestConfig);

      if (response.status === 204) {
        console.log("no response recieved from messages API call")
        callback && callback(true)
        return []
      }
      //console.log(`recieved message history data from api: ${JSON.stringify(response)}`)
      const chats: AnyMessage[] = []
      //console.log(`recieved response.data from api: ${JSON.stringify(response.data)}`)

      if (response.data.length>0) {
        response.data.forEach((message: AnyMessage) => {
        chats.push(message)
        });
      }
      else {
        callback && callback(true)
        return []
      }  
      //console.log(`returning list of parent messages ${JSON.stringify(chats)}`)
      callback && callback(false)
      return chats;
    } catch (error: any) {
      callback && callback(false)
      throw handleApiError(error)
  }
  }
  

export default fetchMessageHistory;
