import React from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

export const IdentityBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: "flex-start",
}));

export const ProfileContentContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    ustifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
});

export const ProfileAccountContainer  = styled(Box)({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-end',
      width: '100%',
      height: '200px',
      padding: '20px',
});

export const ProfileAddInterestButton = styled(Button)({
    padding: '10px',
    backgroundColor: "#ffe98a",
    color: 'black', 
    borderRadius: '20px',
    marginTop: '10px',
    marginLeft: '10px', 
    '&:hover': {
        backgroundColor: '#EFC100', // Hover background color
      },
    '&:active': {
        backgroundColor: '#ffd523', // Background color when clicked
        boxShadow: 'none' 
      },
    })

export const ProfileHorizontalPanel = styled(Box)({
    display:'flex',
    flexDirection:'row',
    justifyContent:'left',
    backgroundColor:'white',
    position:'relative',
    alignItems:'flex-end',
    marginLeft: '20px',
    width:'calc(100% - 80px)',
    minWidth: '300px',
    height:'auto',
    maxHeight: '100%',
    padding:'20px',
    yoverflow:'auto',
    marginBottom:'20px',
    borderRadius:'10px',
    boxShadow:'0px 0px 20px rgba(0,0,0,0.1)',
  });

export const ProfileMenu = styled(Box)({
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    backgroundColor:'white',
    maxWidth:'100px',
    zIndex:'100',
    position:'absolute',
    top:'0px',
    right:'0px',
  });

export const InterestItem = styled(Box)(({ theme }) => ({
    margin: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderRadius: '20px',
    backgroundColor: '#ffd523', // Yellow button color
    color: 'black', // Black text color
    padding: '10px 10px',
}));

export const InterestItemText = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
}));

export const InterestItemRemoveButton = styled(Button)(({ theme }) => ({
    backgroundColor: 'rgba(255, 255, 255, .7)',
    color: 'black',
    justifyContent: 'center',
    borderRadius: '20px',
    maxWidth: '20px',
    minWidth: '20px',
    border: 'none',
    marginLeft: '10px',
    cursor: 'pointer',
    padding: '0',
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, .3)',
    },
}));