import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItemIcon, ListItemText, IconButton, Box, CssBaseline, Typography, ListItemButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Menu as MenuIcon } from '@mui/icons-material';
import { TipsAndUpdates as TipsAndUpdatesIcon, Sms as SmsIcon, History as HistoryIcon, Person as PersonIcon, HelpCenter as HelpCenterIcon, PersonPinCircle as PersonPinCircleIcon } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useMediaQuery } from '@mui/system';

const drawerWidth = 240; // Full expanded width
const collapsedWidth = 60; // Collapsed width (just icons)

// Manually define the height and padding for the toolbar
const DrawerHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 8px', // You can adjust the padding as needed
  height: '64px', // Default AppBar height (you can change this if needed)
});

// MenuButton component to handle menu items with icon and navigation
interface MenuButtonProps {
  text: string;
  icon: React.ElementType; // Accepts any icon component
  selectedText: string;
  setSelectedText: (text: string) => void;
  navigateTo: () => void;
}

const MenuButton: React.FC<MenuButtonProps> = ({ text, icon: Icon, selectedText, setSelectedText, navigateTo }) => {
  const [ishovered, setIsHovered] = useState(false);
  const [isselected, setIsSelected] = useState(selectedText === text);
  const isphone = useMediaQuery('(max-width:800px)');
  const istablet = useMediaQuery('(max-width:1024px)');
  const isDesktop = useMediaQuery('(min-width:1024px)');

  useEffect(() => {
    setIsSelected(selectedText === text);
  }, [selectedText, text]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    setSelectedText(text);
    navigateTo(); // Trigger the navigation
  };

  return (
    <ListItemButton
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      selected={isselected}
    >
      <ListItemIcon>
        <Icon style={{ 
          color: isselected || ishovered ? '#FFDD57' : '#ffffff',
          fontSize: '30px'
       }} />
      </ListItemIcon>
      <ListItemText
  primary={text}
  sx={{
    color: 'white',
    '& .MuiTypography-root': { color: 'white' }, // Ensures that nested Typography also has white color
  }}
/>
    </ListItemButton>
  );
};

// Main PersistentDrawer component
interface PersistentDrawerProps {
  selectedText: string;
  setSelectedText: (text: string) => void;
  children: React.ReactNode;
}

const PersistentDrawer: React.FC<PersistentDrawerProps> = ({ selectedText, setSelectedText, children }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width:1024px)');

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const handleHelp = () => {
    window.location.href = 'mailto:support@campusevolve.ai?subject=Help%20Request&body=Please%20describe%20your%20issue.';
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* Drawer Component */}
      <Drawer
        variant="permanent"
        open={isDesktop || open}
        sx={{
          height: '100vh', // Full viewport height
          display: 'flex',
          flexDirection: 'column',
          //justifyContent: 'space-between', // Space between the top and bottom lists
          width: open? drawerWidth : collapsedWidth,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          '& .MuiDrawer-paper': {
            backgroundColor: '#0097b2',
            width: open? drawerWidth : collapsedWidth,
            transition: 'width 0.3s', // Smooth transition
            overflowX: 'hidden',
            display: 'flex'
          },
        }}
      >
        {/* Header for Collapse/Expand Button */}
        <DrawerHeader>
          <IconButton onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
        </DrawerHeader>

        {/* List of Menu Items */}
        <Box sx={{height: 'calc(100% - 64px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        <List>
          <MenuButton
            text="PATH"
            icon={PersonPinCircleIcon}
            selectedText={selectedText}
            setSelectedText={setSelectedText}
            navigateTo={() => handleNavigation('../landing')}
          />
          <MenuButton
            text="CHAT"
            icon={SmsIcon}
            selectedText={selectedText}
            setSelectedText={setSelectedText}
            navigateTo={() => handleNavigation('../chat')}
          />
          <MenuButton
            text="HISTORY"
            icon={HistoryIcon}
            selectedText={selectedText}
            setSelectedText={setSelectedText}
            navigateTo={() => handleNavigation('../history')}
          />
          <MenuButton
            text="BOOKMARKS"
            icon={TipsAndUpdatesIcon}
            selectedText={selectedText}
            setSelectedText={setSelectedText}
            navigateTo={() => handleNavigation('../resources')}
          />
        </List>

        <List sx={{marginBottom: '50px'}}>
          <MenuButton
            text="HELP"
            icon={HelpCenterIcon}
            selectedText={selectedText}
            setSelectedText={setSelectedText}
            navigateTo={handleHelp}
          />
          <MenuButton
            text="PROFILE"
            icon={PersonIcon}
            selectedText={selectedText}
            setSelectedText={setSelectedText}
            navigateTo={() => handleNavigation('../profile')}
          />
        </List>
        </Box>
      </Drawer>

      {/* Main content area where children are rendered */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children} {/* Render children here */}
      </Box>
    </Box>
  );
};

export default PersistentDrawer;