import React, { useEffect, useState } from 'react';
import {  Box,  Typography, List, ListItem, Link, ListItemText, Grid } from '@mui/material';
import {useMediaQuery} from '@mui/system'
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import {  ResourceBgBox, ResourceBox, ResourceLabel } from './shared';
import  { ResourceList } from '../../api/resources/fetchResourceCategories';
import { Bookmarks } from '../../api/resources/fetchResources';
import { Link as RouterLink } from 'react-router-dom';
import hat_small from '../../assets/images/hat_small.png';
import { BookmarkAdd } from '@mui/icons-material';
import { useAppContext } from '../app/appContext';
import { useNavigate } from 'react-router-dom';
interface PositionElements {
    top?: string;
    left?: string;
    imgLink: string;
}

interface LandingData {
    items: string[];
    category: string;
}
interface LandingItemsProps {
    positions: PositionElements;
    data: LandingData;

}
interface InnerLandingItemProps {
    message: string;
}

const InnerLandingItem: React.FC<InnerLandingItemProps> = ({ message }) => {
    const navigate = useNavigate();
    const handleLandingItemClick = (item: string) =>
    {
        navigate('../chat', { state: { term: message } });
    };
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
            alignContent: 'center',
            alignItems: 'center',
            p: '0px'
        }}>
            <PersonPinCircleIcon sx={{
                fontSize: '20px',
                color: '#FFDD57'}}/>
        <ListItem sx={{
            width: '100%',
            justifyContent: 'flex-start',
            p: '0px'
        }}>
           <Typography
               onClick={() => handleLandingItemClick(message)}
                sx={{
                    textDecoration: 'underline',
                    color: 'blue',
                    cursor: 'pointer',
                    '&:hover': {
                        color: 'darkblue'
                    }
                }}
                variant="body2"
                >
                <ListItemText primary={message}
                    primaryTypographyProps={{ variant: 'body3' }} />
            </Typography>
        </ListItem>
        </Box>

    )
}

interface GetLandingItemsProps {
  data: string[];
}
const GetLandingItem: React.FC<GetLandingItemsProps> = ({data}) => {
    if (data.length > 0) {
        return (
            <Box>
                {data.slice(0, 4).map((item: string, index) => (
                    <InnerLandingItem key={index} message={item} />
                ))}
                <Box height='20px' />
            </Box>
        )
    }
    else {
        return (
            <Link component={RouterLink} to='/chat' underline='none'>
                <Grid container>
                    <Grid item xs={3}>
                        <img src={hat_small}
                            alt="hat"
                            width="auto"
                            height="40px"
                            style={{ marginBottom: '40px' }}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body3" sx={{ textAlign: 'left' }}>
                            You haven't bookmarked <BookmarkAdd sx={{fontSize:'.8rem'}}/> any resources in this category yet!
                            Chat with me to add content.
                        </Typography>
                    </Grid>
                </Grid>
            </Link>
        )
    }
    return <></>
};

const LandingItems: React.FC<LandingItemsProps> = ({
    positions,
    data
}) => {
    //console.log(`mapping landing item for ${data.category}`)
    const {isphone} = useAppContext();
  
    return (
       <ResourceBox id='resource-box-container' isphone={isphone}
       top={positions.top || ''} left={positions.left || ''}>
            <ResourceBgBox id='resource-item-header-container' bgimg={positions.imgLink}>
                <ResourceLabel textAlign='center'>
                    {data.category}
                </ResourceLabel>
            </ResourceBgBox>
            <List sx={{ padding: 2 }}>
                <GetLandingItem data={data.items}/>
            </List>

        </ResourceBox> 
    )
}


export default LandingItems;