// A Memoized Component for Rendering Chat History
import { Box, Typography, CircularProgress } from "@mui/material";
import React, { FC, useEffect, useRef } from "react";
import "../../../../assets/styles.css";
import { AnyMessage } from "../../../../model/nodeTypes";
import ChatBotChat from "./chatBotChat";
import ChatUserChat from "./chatUserChat";
import AppStatus from "../../../../model/conversation/statusMessages";
import ChatBotChatError from "./chatResponse/chatBotChatError";
import { useAppContext } from "../../../app/appContext";

interface ChatHistoryProps {
  /** An array of messages to display in the chat. */
  messages: AnyMessage[];
  onRetry?: () => void;
  passRef?: (ref: HTMLDivElement | null) => void;
  selectedMessage?: AnyMessage;
}

const ChatMessageHistory: FC<ChatHistoryProps> = React.memo(({ 
  messages,
  onRetry,
  passRef,
  selectedMessage,
}) => {
  //console.log(`chat message history set to: ${JSON.stringify(messages)}`)
  const scrollRef = useRef<HTMLDivElement>(null);
  const selectedMessageRef = useRef<HTMLDivElement>(null);
  const {appStatus} = useAppContext();

  useEffect(() => {
    if (passRef){
      if (selectedMessageRef.current && selectedMessage) {
        passRef(selectedMessageRef.current)
      }
      else if (scrollRef.current) {
      passRef(scrollRef.current)
    }
  }
  }, [messages, scrollRef, selectedMessageRef, selectedMessage, appStatus]);

  return (
    <Box id={'chat-message-history'} sx={{
     overflowY: 'auto',
      paddingRight: '20px'
      }}>
      {messages.map((m, index) => {
        const isselected = selectedMessage &&
        selectedMessage.object_id &&
        m.object_id &&
        m.object_id === selectedMessage.object_id;
        return (
          <React.Fragment key={index}>

            {m.role === "user" && (
              <>
                <ChatUserChat text={m.message_text} />
                <Box sx={{ height: "20px" }} />
              </>
            )
            }

            {m.role === "system" && (
              <>
                <ChatBotChat message={m} onRetry={onRetry} />
                <Box sx={{ height: "20px" }} />
              </>
            )
            }
            {isselected && <div id='selected-search-ref' ref={selectedMessageRef}></div>}
          </React.Fragment>
        )
      })}
        {(appStatus === AppStatus.GeneratingChatResponse ||
          appStatus === AppStatus.GettingMessageHistory)
          && <CircularProgress />}
        {appStatus === AppStatus.GeneratingChatResponse &&
          <Typography variant="body1" color="textSecondary" align="center">
            Generating response...
          </Typography>}
      {messages && <div id='bottom-of-chat-history' ref={scrollRef} ></div>}
    </Box>
  )
})

export default ChatMessageHistory;