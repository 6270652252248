import React, { ReactNode } from 'react';
import { Box, Button, Drawer, BoxProps } from '@mui/material';

interface CustomExtensionProps extends BoxProps {
    ishovered?: boolean;
    isselected?: boolean;
    isphone?: boolean;
    istablet?: boolean;
}

const ExtendedBox = React.forwardRef<HTMLDivElement, CustomExtensionProps>(
    ({ ishovered = false,
        isselected = false, 
        isphone = false,
        istablet = false,
        ...rest }, ref) => (
      <Box ref={ref} {...rest} />
    )
  );
  
  export default ExtendedBox;