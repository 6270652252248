import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import { UserModel } from '../../model/nodeTypes';
import { deleteProfile } from '../../api/user/deleteProfile';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
interface ProfileDeleteModalProps{
    deleteClick: boolean;
    setDeleteClick: React.Dispatch<React.SetStateAction<boolean>>;
    user: UserModel
}

const ProfileDeleteModal = ({ deleteClick, setDeleteClick, user }: ProfileDeleteModalProps) => {
  const [deleteEmail, setDeleteEmail] = useState('');
  const [error, setError] = useState('');
  const { logout } = useAuth0();
  // Handle email input change
  const handleEmailChange = (email: string) => {
    setDeleteEmail(email);
    setError('');  // Reset error on input change
  };

  const handleClose = () => {
    setDeleteClick(false);
  }

  // Handle form submission
  const handleSubmit = () => {
    setError('')
    if (user && user.first_name && deleteEmail === user.first_name) {
      //TODO profile delete function

      deleteProfile()
      sessionStorage.clear();
      logout({logoutParams: { returnTo: window.location.origin }})

    } else {

      setError(`The email address does not match your profile name: ${user?.first_name}`);
    }
  };

  return (
    <Modal open={deleteClick} onClose={handleClose}>
      <Box sx={{ p: 4, backgroundColor: 'white', width: '300px', mx: 'auto', mt: '20vh', borderRadius: '8px' }}>
        <Typography variant='h4' textAlign={'center'}>
            We are sorry to see you go!
        </Typography>
        <Typography variant="h6" >
          Are you sure you want to delete your profile? All chat history and bookmarks will be lost
          and cannot be recovered.
        </Typography>
        <TextField
          label="Confirm your name to delete"
          name="name"
          value={deleteEmail}
          onChange={(e) => handleEmailChange(e.target.value)}
          fullWidth
          error={!!error} // Show error if it exists
          helperText={error} // Display the error message
          sx={{ mt: 2 }}
        />
        <Button
          variant="contained"
          color="error"
          onClick={handleSubmit}
          fullWidth
          sx={{ mt: 2 }}
        >
          Confirm Delete?
        </Button>
      </Box>
    </Modal>
  );
};

export default ProfileDeleteModal;