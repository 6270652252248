import React from 'react';
import NewChat from '../chat/newChat';
import AppContainer from './appContainer';
const AppHome: React.FC = () => {

  return (
    <AppContainer>
            <NewChat />
          </AppContainer>
  );
};

export default AppHome;