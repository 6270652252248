
import { Box, Grid } from "@mui/material";
import propTypes from "prop-types";
import React, { FC, useEffect, useContext, useRef } from "react";
import ChatBotChatResponse from "./chatResponse/chatBotChatResponse";
import ChatBotChatError from "./chatResponse/chatBotChatError";
import hat_small from "../../../../assets/images/hat_small.png";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { ChatBotResponseContainer, ChatBotResponseIconsContainer } from "../../shared";
import ThumbsDownReview from "./ratingBoxes";
import { Timestamp } from "../../../../model/messages/messages";
import sendFeedback, {FeedbackData} from "../../../../api/chat/sendFeedback";
import { AnyMessage, BotMessage } from "../../../../model/nodeTypes";
import setBookmarks from "../../../../api/chat/bookmarkChat";
import { Snackbar, Alert, Popover, AlertColor } from "@mui/material";
import { deleteBookmark } from "../../../../api/resources/deleteBookmark";
import {MUIError} from "../../../../api/apiErrorHandler"
import { useAppContext } from "../../../app/appContext";

interface ChatBotChatProps {
  message?: AnyMessage;
  onRetry?: () => void;
}

/**
 * A component that displays a chat message from a chatbot.
 * @param isLoading - Is the component in the loading state?
 * @param isError - Is the component in the error state?
 * @param message - The message to display.
 * @param onRetry - Callback to retry loading the message.
 * @param error - The error message to display.
 * @returns A React component that displays a chat message from a chatbot.
 */
const ChatBotChat: FC<ChatBotChatProps> = ({
  message,
  onRetry,
}) => {

  const iconContainerRef = useRef<HTMLDivElement>(null);
  const [formatted_message, setFormattedMessage] = React.useState<string>('');
  const [isDislike, setIsDislike] = React.useState<boolean>(false);
  const [selectedFeedbackIcon, setSelectedFeedbackIcon] = React.useState<string>('');
  const [bookmarkIsSelected, setBookmarkIsSelected] = React.useState<boolean>(message && message.message_is_bookmarked || false);
  const bookmarkIconRef = useRef<HTMLDivElement>(null);
  const [bookmarkAnchor, setBookmarkAnchor] = React.useState<HTMLElement | null>(null); 
  const {isError, setErrMessage, setIsError, setErrSev} = useAppContext();
  const [hasHyperlink, setHasHyperlink] = React.useState<boolean>(false);

  // Regex to check for hyperlinks
  const checkForHyperlink = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return urlRegex.test(text);
  };

  function hyperlinkPreviousWord(text: string) {
    let links = 0;
    let cleanedText = text.replace(/\[\s*(Source|Links)?:?\s*\]|\(\s*(Source|Links)?:?\s*\)/gi, '');
    const urlRegex = /([^.?!]+\s?\b)\s\[(https?:\/\/\S+)\]/gi;
    let replacement = cleanedText.replace(urlRegex, (match, precedingSentence, url) => {
      // Replace the matched pattern with the preceding sentence wrapped in an anchor tag
      return `<a target="_blank" style="text-decoration: underline;" href="${url}">${precedingSentence}</a>`;
    });
    return replacement;
  }

  const handleCloseFeedback = () => {
    //console.log('closing feedback box');
    setIsDislike(false);
  };

  const handleIconClick = (icon: string) => {
    if (selectedFeedbackIcon == icon)
    {
      setSelectedFeedbackIcon('')
    }
    else {
      setSelectedFeedbackIcon(icon)
    }
  };

  const handleSendFeedback = async (newFeedback: FeedbackData) => {
    try{
     await sendFeedback(newFeedback);
    }
    catch (error: any){
      if (error instanceof MUIError) {
      setErrSev(error.severityLevel || 'error')
      setIsError(true)
      setErrMessage(error.message)
  } else {
    setIsError(true)
    setErrMessage("Unknown Error saving bookmarks")
  }
  }
}

  useEffect(()=>{
    if (selectedFeedbackIcon && selectedFeedbackIcon=='dislike') {
      setIsDislike(true);
    }
    if (selectedFeedbackIcon && selectedFeedbackIcon=='like' && message && message.object_id) {
      const newFeedback: FeedbackData = {
        rating_reasons: [],
        comments: '',
        date_rated: message.created_at || 0,
        sentiment: 'positive',
        object_id: message.object_id,
    };
      handleSendFeedback(newFeedback);
    }
  }
  ,[selectedFeedbackIcon])


  const deleteBookmarkAsync = async (passedId: string) =>
  {
    try {
      deleteBookmark({deleteId: passedId})
      setBookmarkIsSelected(!bookmarkIsSelected)
      if (message) {
        message.message_is_bookmarked = false; 
      }
  } catch (error: any) {
    if (error instanceof MUIError) {
      setErrSev(error.severityLevel || 'error')
      setIsError(true)
      setErrMessage(error.message)
  } else {
    setIsError(true)
    setErrMessage("Unknown Error saving bookmarks")
  }
}
}

  const handleBookmark = () => {
    //console.log('Bookmark icon clicked');
    if (message) {
      if (!bookmarkIsSelected) {
      //console.log('setting new bookmark')
      const newBotMessage: BotMessage = {
        object_id: message.object_id,
        role: 'system'
      }
      setBookmarkAsync(newBotMessage)
    }
    else if (bookmarkIsSelected)
    {
      //console.log(`removing bookmark from message`)
      message.object_id && deleteBookmarkAsync(message.object_id)
    }
  }
  };

  const setBookmarkAsync = async (message: BotMessage) =>{
    try{
      //console.log('trying set bookmarks')
      await setBookmarks({ message: message })
      setBookmarkIsSelected(true)
      if (message) {
        message.message_is_bookmarked = true; 
      }
    }
    catch (error: any) {
      if (error instanceof MUIError) {
        setErrSev(error.severityLevel || 'error')
        setIsError(true)
        setErrMessage(error.message)
    } else {
      setIsError(true)
      setErrMessage("Unknown Error saving bookmarks")
    }
  }
  }

  // Handle checking for hyperlinks when message changes
  useEffect(() => {
    if (message && message.message_text) {
      setFormattedMessage(hyperlinkPreviousWord(message.message_text || ''));
      setHasHyperlink(checkForHyperlink(message.message_text)); // Set state if message contains hyperlink
    }
  }, [message]);
  return (
    <Box id="chatBotContainer" display={'flex'} flexDirection={'column'} justifyContent={'flex-start'}>
      <Box id="chatBotContainerSpacer" width='70%' minWidth='300px' alignItems={'flex-end'}>
        <Grid id="ChatBotResponseIconGrid" container>
          <Grid item xs={1} display={'flex'} alignItems={'flex-end'}>
            <img src={hat_small} alt="hat" width="auto" height="40px" style={{ marginBottom: '40px' }} />
          </Grid>
          <Grid item xs={11}>
            <ChatBotResponseContainer id='ChatBotResponseTextContainer'>
              {isError ? <ChatBotChatError onRetry={onRetry}/>
                : message ? <ChatBotChatResponse message={formatted_message} />
                  : <></>
              }
            </ChatBotResponseContainer>

            <ChatBotResponseIconsContainer ref={iconContainerRef}>
              <Box ref={bookmarkIconRef}>
                <ThumbUpIcon 
                onClick={(e) => { e.stopPropagation(); handleIconClick('like'); }} 
                sx={{ marginRight: '10px', 
                color: selectedFeedbackIcon=='like'? 'rgb(178, 27, 0)' : 'inherit',
                cursor: 'pointer' }} />
                <ThumbDownIcon 
                onClick={(e) => { e.stopPropagation(); handleIconClick('dislike') }} 
                sx={{ marginRight: '10px', 
                color: selectedFeedbackIcon=='dislike'? 'rgb(178, 27, 0)' : 'inherit',
                cursor: 'pointer' }}>
                </ThumbDownIcon>
                {hasHyperlink && <BookmarkAddIcon 
                onClick={handleBookmark} 
                sx={{
                  marginRight: '10px',
                  cursor: 'pointer',
                  color: bookmarkIsSelected ? 'rgb(178, 27, 0)' : 'inherit'
                }} />}
              </Box>
            </ChatBotResponseIconsContainer>
            {isDislike && message && message.object_id && <ThumbsDownReview handleClose={handleCloseFeedback} messageTimestamp={message.created_at} messageObjectId={message.object_id} />}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

ChatBotChat.propTypes = {
  /**
   * The message to display
   */
  message: propTypes.any,
  /**
   * Callback to retry loading the message
   */
  onRetry: propTypes.func

};

export default ChatBotChat;
