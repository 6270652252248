import axios from 'axios';
import { BaseUrl } from "../baseURL";
import { handleApiError } from '../apiErrorHandler';

export interface FetchQuestionResult {
  questions: string[];
}


const fetchSampleQuestions = async ():Promise<FetchQuestionResult> => {
  const apiUrl = `${BaseUrl()}/suggest-questions`;
  console.log(`fetching questions for user`)
  try {

    const requestConfig = {
      url: apiUrl,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
    };
    const response = await axios(requestConfig);
    console.log("got suggested questions: ", JSON.stringify(response.data))
    //debugger;
    return response.data;

  } catch (error: any) {
    throw handleApiError(error)
}
}

export default fetchSampleQuestions;
