import React from 'react';
import { Box, Typography, Autocomplete, TextField} from '@mui/material';
import { School, Handshake, Chat } from '@mui/icons-material'; // Import MUI icons
import { styled, useMediaQuery } from '@mui/system';
import { ContinueButton, Step, StepContainer, Root, Line, Panel, Spacer, mediaSize, gridSize } from './shared';
import FreSteps from './base';
import { useNavigate } from 'react-router-dom';
import grad_hat from '../../assets/images/happy_hat.png';

const FirstChat: React.FC = () => {

  const navigate = useNavigate();
  const isphone = useMediaQuery('(max-width:450px)');
  const istablet = useMediaQuery('(max-width:1024px)');
  
  const size = mediaSize({isphone, istablet}); 
  const gridValue = gridSize({isphone, istablet});
  

  const startChat = () => {
    navigate('../chat');
  };

  return (
    <Root>
      <Panel>
        <Box display='flex' flexDirection={isphone? 'column': 'row'} justifyContent={'start'} alignItems={'center'}>
        <img src={grad_hat} alt="Celebration" height="300px" />
        <Box display='flex' flexDirection={'column'} justifyContent={'start'} alignItems={isphone? 'center': 'start'}>
      <Typography variant="h1" color={
            'black' }>
            You did it!
          </Typography>
          <Box height='20px'></Box>
          <Typography variant="body1" color={
            'black' }>
            I am ready to help you with your college journey. Let's get started!  
          </Typography>
          </Box>
          <Box height='20px'></Box>
      </Box>
      <ContinueButton variant="contained" disabled={false} onClick={startChat}>
       Start Chatting
      </ContinueButton>
      </Panel>
    </Root>
  );
};


export default FirstChat;