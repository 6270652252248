import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { blueGrey, cyan, pink } from '@mui/material/colors';

// Extend the TypographyVariants interface to include the custom body3 variant
declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  // Allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3: React.CSSProperties;
  }
}

// Extend the Typography's variant prop options to include body3
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

// Light theme configuration
export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '#ecf0f1',
      main: '#000',
      dark: '#00697c',
      contrastText: '#fff',
    },
    secondary: {
      light: '#81d4e4',
      main: '#D50032',
      dark: '#6a767c',
      contrastText: '#fff',
    },
    warning: {
      main: '#ba1a1a',
    },
    info: {
      main: '#ffddbb',
    },
    success: {
      main: '#b3bc52',
    },
    text: {
      primary: '#171a1c',
      secondary: '#000',
    },
  },
  typography: {
    fontFamily: 'Roboto',
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 800,
    button: {
      fontSize: '1rem',
      fontWeight: 300,
      color: '#fff',
      textTransform: 'none',
      lineHeight: 2,
      '&:hover': {
        backgroundColor: '#FFDD57',
        color: '#000',
      },
      '&:active': {
        backgroundColor: '#FFDD57',
        color: '#000',
      },
    },
    h1: {
      fontSize: '3rem',
      fontWeight: 500,
      letterSpacing: 1.2,
      color: '#000',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 300,
      color: '#000',
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 300,
      color: '#fff',
    },
    body3: {
      fontSize: '.8rem',
      fontWeight: 300,
      color: '#000',
      fontFamily: 'roboto',
      textAlign: 'left',
    },
    h2: {
      fontSize: '1.8rem',
      fontWeight: 400,
      color: '#000',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 300,
      color: '#fff',
    },
    h4: {
      fontSize: '1.2rem',
      fontWeight: 500,
      color: '#000',
    },
    h5: {
      fontSize: '1.2rem',
      fontWeight: 500,
      color: '#fff',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#000',
    },
  },
});

export default theme;