import React, { createContext, useContext, useState, useEffect, useRef, useMemo } from 'react';
import FetchHistory, { HistoryItems, HistoryRefresh } from '../../api/history/fetchHistory';
import { UserModel, School } from '../../model/nodeTypes';
import fetchProfile from '../../api/user/fetchProfile';
import fetchSchools from '../../api/user/fetchSchools';
import { useMediaQuery } from '@mui/system';
import { Popover, Alert, AlertColor } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { default as updateUserProfile } from '../../api/user/setProfile';
import { MUIError } from '../../api/apiErrorHandler';
import AppStatus from '../../model/conversation/statusMessages';

interface AppContextProps {
  profile: UserModel | null;
  setProfile: React.Dispatch<React.SetStateAction<UserModel | null>>;
  appStatus: AppStatus;
  setAppStatus: React.Dispatch<React.SetStateAction<AppStatus>>;
  schoolOptions: School[];
  history: HistoryItems[];
  uniqueSchoolNames: string[];
  isphone: boolean;
  istinyphone: boolean;
  istablet: boolean;
  accessToken: string;
  isAppLoading: boolean;
  isError: boolean;
  setIsAppLoading: React.Dispatch<React.SetStateAction<boolean>>;
  callback: () => void;
  setCallback: React.Dispatch<React.SetStateAction<() => void>>;
  setErrMessage: React.Dispatch<React.SetStateAction<string>>;
  setErrSev: React.Dispatch<React.SetStateAction<AlertColor>>;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  errMessage: string;
  setErrRedirect: React.Dispatch<React.SetStateAction<string | ''>>;
  getHistoryFromLocalStorage: () => void;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [profile, setProfile] = useState<UserModel | null>(null);
  const [school, setSchool] = useState<string>();
  const [schoolOptions, setSchoolOptions] = useState<School[]>([]);
  const [history, setHistory] = useState<HistoryItems[]>([]);
  const profileRef = useRef<UserModel | null>(profile);
  const isphone = useMediaQuery('(max-width:800px)');
  const istablet = useMediaQuery('(max-width:1024px)');
  const istinyphone = useMediaQuery('(max-width:400px)');
  const [accessToken, setAccessToken] = useState<string>('');
  const [isAppLoading, setIsAppLoading] = useState<boolean>(false);
  const { isAuthenticated, isLoading } = useAuth0();
  const [isError, setIsError] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>('');
  const [errSev, setErrSev] = useState<AlertColor>('error');
  const [appStatus, setAppStatus] = useState<AppStatus>(AppStatus.Idle);
  const [errRedirect, setErrRedirect] = useState<string>('');

  const nav = useNavigate();
  const { logout } = useAuth0();

  const doLogout = () => {
    console.log('clearing storage')
    sessionStorage.clear();
    setErrRedirect('');
   //debugger
    logout();
  }
  const processError = (error: any) => {
    if (error instanceof MUIError) {
      setErrMessage(error.message)
      setErrSev(error.severityLevel || 'error')
      setErrRedirect(error.redirectLink || '')
      console.error(JSON.stringify(error))

    } else {
      setErrMessage("Unknown error. If this continues to happen please contact us at support@campusevolve.ai")
    }
    console.log(errMessage, error);
    setIsError(true)
  }

  const [callback, setCallback] = useState<() => void>(() => () => {
    console.log('Default function');
  });

  const refreshSeconds = (30 * 60 * 1000) //30 minutes in miliseconds

  const getHistoryFromLocalStorage = () => {
    const fetchStatus = sessionStorage.getItem('historyFetch');
    //debugger;
    if (fetchStatus=='fetching' || !profileRef.current){
      return;
    }
    const historyFromStorage: HistoryRefresh | null= (() => {
      const item = sessionStorage.getItem('userHistory');
      console.log(`getting history from local storage ${item}`)
      try {
        if (item) {
          console.log('Parsing history item');
          const parsedHistory = JSON.parse(item);
    
          // Always check and convert refresh_time if it's a string
          if (parsedHistory.refresh_time) {
            console.log(`Current refresh_time type: ${typeof parsedHistory.refresh_time}`);
            if (typeof parsedHistory.refresh_time === 'string') {
              console.log('Converting refresh_time from string to Date');
              parsedHistory.refresh_time = new Date(parsedHistory.refresh_time);
            }
            console.log(`Converted refresh_time: ${parsedHistory.refresh_time}`);
          } else {
            console.log('No refresh_time found, setting new Date');
            parsedHistory.refresh_time = new Date(); // Fallback to current date if it's missing
          }
    
          return parsedHistory;
        }
        return null;
      } catch (error: any) {
        const passErr = new MUIError(
          `Unable to parse history from storage with error ${error.message}`,
          'warning'
        );
        processError(passErr);
        return null;
      }
    })();
    
    if (historyFromStorage) {
      const currentDate = new Date()
      //if history obtained from local storage, see how old it is and refresh if needed
       if (((currentDate.getTime() - historyFromStorage.refresh_time.getTime()) > refreshSeconds)) {
          console.log('history refresh time expired.  Getting new history ')
          fetchHistory()
       }
      else {
      setHistory(historyFromStorage?.history_items || [])
      }
    }
    else {
      //other wise get the history (nothing in storage)
      sessionStorage.setItem('historyFetch', 'fetching');
      fetchHistory();
    }
  }

  const updateProfile = async (): Promise<void> => {
    try {
      profile && await updateUserProfile({ userProfile: profile, callback: callback });
    }
    catch (error: any) {
      processError(error)
    }
  };

  useEffect(() => {
    if (!profileRef.current)
     {
      profileRef.current = profile;
      //debugger;
      return
     }
    if (JSON.stringify(profile) != JSON.stringify(profileRef.current)) {
      //debugger;
      profileRef.current = profile;
      setSchool(profile?.is_primary_school?.[0]?.school_name || '')
      updateProfile()
    }
  },
    [profile])


  //on load attempt to get access token.  This token is set in the protected route during the auth process. 
  //polling is used in case the page loads before the token is recieved from the server in authentication
  useEffect(() => {
    const interval = setInterval(() => {
      console.log('checking for token in local storage')
      const token = sessionStorage.getItem('accessToken');
      if (token) {
        setAccessToken(token);
        clearInterval(interval);
      }
    }, 500); // Check every 500ms
    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  useEffect(() => {
    if (isLoading) {
      setIsAppLoading(true);
      console.log('app is still loading, not ready to process data')
      return;
    }
    if (isAuthenticated && !isLoading && accessToken) {
      console.log('checking variables')
      //debugger;
      setIsAppLoading(false);
      getSchools();
      getUserProfileFromLocalStorage();
      getHistoryFromLocalStorage();
      memoizedSchoolOptions;
    }
  }, [accessToken, isLoading, isAuthenticated]);

  const fetchHistory = async () => {
    try {
      setAppStatus(AppStatus.GettingMessageHistory)
      //debugger;
      await FetchHistory({setAppStatus: setAppStatus, setHistory: setHistory});
    } catch (error: any) {
      processError(error)
    } finally {
      sessionStorage.setItem('historyFetch', '');
    }
  };

  const fetchNewProfile = async (): Promise<void> => {
    try {
      const result = await fetchProfile({ includeRelationships: false });
      setProfile(result);
    }
    catch (error) {
      processError(error)
    }
  };

  const getUserProfileFromLocalStorage = async (): Promise<void> => {
    const storedProfile = sessionStorage.getItem('userProfile');
    if (storedProfile) {
      let profile: UserModel = JSON.parse(storedProfile) as UserModel;
      if (!profile) {
        fetchNewProfile();
      }
      else {
        setProfile(profile);
        profileRef.current = profile
      }
    }
    else {
      fetchNewProfile();
    }
  };

  const getSchools = async () => {
    try {
      await fetchSchools(setSchoolOptions);
    } catch (error) {
      processError(error)
    }
  };


  const memoizedSchoolOptions = useMemo(() => {
    return schoolOptions;
  }, [schoolOptions]); // Only re-run if schoolOptions change



  const uniqueSchoolNames = Array.from(
    new Set(
      schoolOptions
        .map((option) => option.school_name)
        .filter((school_name) => school_name !== null && school_name !== undefined)
    )
  ).sort((a, b) => a.localeCompare(b));  // Sort alphabetically using localeCompare

  const handleCloseError = () => {
    console.log('closing error message')
    setIsError(false);
    setErrMessage('');
    setErrSev('error');
    
    if (errRedirect) {
      console.log('handling redirect')
      if (errRedirect == '/') {
        setErrRedirect('');
        doLogout();
      }
      else if (errRedirect == '../'){
        setErrRedirect('');
        window.location.reload()
      }
      else {
        setErrRedirect('')
        nav(errRedirect)
      }
    }
  }
  const ErrorToast: React.FC = () => {
    return (
      <Popover
        id='error-message'
        open={isError}
        anchorPosition={{ top: 100, left: 100 }}
        onClose={handleCloseError}  // Close the Popover
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}  // Control how the popover transforms from the anchor
      >
        <Alert onClose={handleCloseError} severity={errSev} sx={{ width: '100%' }}>
          {errMessage}
        </Alert>
      </Popover>
    );
  };

  return (
    <AppContext.Provider
      value={{
        profile,
        setProfile,
        schoolOptions,
        history,
        uniqueSchoolNames,
        isphone,
        istinyphone,
        istablet,
        isAppLoading,
        setIsAppLoading,
        accessToken,
        callback,
        setCallback,
        appStatus,
        setAppStatus,
        setIsError,
        setErrSev,
        setErrMessage,
        isError,
        errMessage,
        setErrRedirect,
        getHistoryFromLocalStorage,
      }}
    >
      {children}
      <ErrorToast />
    </AppContext.Provider>
  );
};