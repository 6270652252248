import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import PublicIcon from '@mui/icons-material/Public';
interface ResourceLinkProps {
    title: string;
    description?: string;
    image_url?: string;
    link: string;
    onClick: (link: string) => void; // Add onClick prop to handle click event
}

const ResourceLink: React.FC<ResourceLinkProps> = ({ title, description, image_url, link, onClick }) => {
    const [displayTitle, setDisplayTitle] = React.useState<string>(title);
    const [imgError, setImgError] = React.useState<boolean>(false);
    
    useEffect(() => {
        !title && setDisplayTitle(link);
        debugger;
    },[])
    return (
        <Box 
            sx={{
                padding: '10px',
                margin: '10px',
                border: '1px solid rgb(0,0,0,.2)',
                borderRadius: '10px',
                width: '250px',
                height: '150px',
                backgroundColor: 'white',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                cursor: 'pointer', // Add pointer cursor to indicate it's clickable
            }}
            onClick={() => onClick(link)} // Handle click event
        >
            <Box sx={{wordBreak: 'break-all'}}>
            <Typography variant="h6">{displayTitle}</Typography>
            </Box>
            <Typography variant="body1">{description}</Typography>
        </Box>
    );
};

export default ResourceLink;