import axios from 'axios';
import { BaseUrl } from "../baseURL";
import { BotMessage } from '../../model/nodeTypes';
import { handleApiError } from '../apiErrorHandler';
interface CreateBookmarksProps {
  message: BotMessage;
}

const setBookmarks = async ({message}: CreateBookmarksProps) => {
  const apiUrl = `${BaseUrl()}/bookmarks`;
  console.log(`sending message data to bookmarks API: ${message}`)
  
  try {
    console.log(`message to bookmark links`);
    const requestConfig = {
      url: apiUrl,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
      data: message,
    };

    const response = await axios(requestConfig);
    //debugger;
    if (response.status === 200) {
      console.log('got response from server', JSON.stringify(response));
      return;  
    } else {
      console.error('Unexpected response format:', response);
      throw new Error('Unexpected response format');
    }
  } catch (error: any) {
    throw handleApiError(error)
}
}

export default setBookmarks;