import React from "react";
import { Autocomplete,  Popper, Box, TextField, FormControl, Paper, FormLabel, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import { useMediaQuery } from '@mui/system';
import { styled } from "@mui/material";
import { useAppContext } from "../app/appContext";
export interface attributeChoiceProps {
    selectedValue?: string;
    setSelectedValue: any;
    options: string[];
    label?: string | null;
    fontColor?: string | null;
    marginProp?: string | null;
}

const StyledPopper = styled(Popper)({
    minWidth: '200px', // Set the min width for the dropdown
  });

  export const ChooseSingleOptionSmall: React.FC<attributeChoiceProps> = ({ selectedValue, setSelectedValue, options, label, fontColor, marginProp }) => {
    const {isphone} = useAppContext();
    const lenLabel = (label?.length || 0)
    return (
        <Autocomplete id='auto-complete-container'
        PopperComponent={StyledPopper} 
            value={selectedValue || 'Any'}
            onChange={(event, newValue) => {
                //debugger;
                newValue && setSelectedValue(newValue);
            }}
            options={options}
            renderInput={(params) => (
                <TextField 
                    {...params}
                    id='inner-text-field'
                    label={label}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: false,
                        sx: {
                            color: 'inherit', // Always white label
                            transform: 'translate(0, -1.5em) scale(1)',
                            padding: '0px 10px',
                            maxWidth: isphone? '300px' : 'auto' ,
                            whiteSpace: 'normal', 
                            wordBreak: 'break-word',
                            overflow: 'visible',
                            marginBottom: 0,
                            position: 'absolute',
                            top: (isphone && lenLabel > 30) ? '-20px' : 0,
                        },
                    }}
                    sx={{
                        minWidth: isphone? '200px':'300px', // Width of the autocomplete input box
                        backgroundColor: 'white',
                        borderRadius: '50px',
                        margin: marginProp? marginProp: '20px 0', // Adjust margins to align to the left
                        textAlign: 'left', // Align text to the left if required
                        '& .MuiOutlinedInput-root': {
                            textAlign: 'left', // Align the text in the input field to the left
                            borderRadius: '60px',
                            height: '50px'
                        },
                        '& .MuiInputLabel-root': {
                            color: fontColor? fontColor : 'white', // Ensure label color stays white
                        },
                    }}

                />
            )}
            PaperComponent={({ children, ...props }) => (
                <Paper id='inner-paper' {...props} sx={{ textAlign: 'left' }}> {/* Ensure dropdown aligns left */}
                    {children}
                </Paper>
            )}
        />

    );
}

export const ChooseSingleOption: React.FC<attributeChoiceProps> = ({ selectedValue, setSelectedValue, options, label, fontColor, marginProp }) => {
    const {isphone} = useAppContext();
    const lenLabel = (label?.length || 0)
    return (
        <Autocomplete id='auto-complete-container'
        PopperComponent={StyledPopper} 
            value={selectedValue || 'Any'}
            onChange={(event, newValue) => {
                //debugger;
                newValue && setSelectedValue(newValue);
            }}
            options={options}
            renderInput={(params) => (
                <TextField 
                    {...params}
                    label={label}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: false,
                        sx: {
                            color: 'inherit', // Always white label
                            transform: 'translate(0, -1.5em) scale(1)',
                            padding: '0px 10px',
                            maxWidth: isphone? '280px' : 'auto' ,
                            whiteSpace: 'normal', 
                            wordBreak: 'break-word',
                            overflow: 'visible',
                            marginBottom: isphone? '1.5rem' : 0,
                            position: 'absolute',
                            top: (isphone && lenLabel > 30) ? '-20px' : 0,
                        },
                    }}
                    sx={{
                        minWidth: '180px', // Width of the autocomplete input box
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        margin: marginProp? marginProp: '20px 0', // Adjust margins to align to the left
                        textAlign: 'left', // Align text to the left if required
                        '& .MuiOutlinedInput-root': {
                            textAlign: 'left', // Align the text in the input field to the left
                        },
                        '& .MuiInputLabel-root': {
                            color: fontColor? fontColor : 'white', // Ensure label color stays white
                        },
                    }}

                />
            )}
            PaperComponent={({ children, ...props }) => (
                <Paper {...props} sx={{ textAlign: 'left' }}> {/* Ensure dropdown aligns left */}
                    {children}
                </Paper>
            )}
        />

    );
}

export const ChooseRadioButtons: React.FC<attributeChoiceProps> = ({ setSelectedValue, label, options }) => {
    const isphone = useMediaQuery('(max-width:450px)');
    const istablet = useMediaQuery('(max-width:1024px)');
    return (
        <FormControl sx={{color: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
            <FormLabel id="demo-radio-buttons-group-label" sx={{ 
                color: 'inherit',
                maxWidth: isphone? '280px' : 'auto' ,
                marginBottom: isphone? '1rem' : 0,
                }}>
            {label}</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={options[0]}
                name="radio-buttons-group"
                onChange={(event) => {
                    const value = event.target.value;
                    setSelectedValue(value);
                }}
                sx={{
                    color: 'inherit'
                }}>
                {options.map((option) => (
                    <FormControlLabel
                        key={option}
                        value={option}
                        control={
                        <Radio sx={{ 
                            color: 'inherit' }} 
                            />}
                        label={option}sx={{
                        '& .MuiFormControlLabel-label': {
                            color: 'inherit' // Ensures the text label is white
                          }
                        }}
                          />
                ))}
            </RadioGroup>
            <Box sx={{ height: isphone? '50px': '20px' }} /> {/* Add spacing between the radio buttons and the text field */}
        </FormControl>

    );
};


