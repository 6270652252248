import React, { useEffect, useState } from 'react';
import { useMediaQuery,  CircularProgress} from '@mui/material';
import FetchResourceCategories, { ResourceList } from '../../api/resources/fetchResourceCategories';
import AppContainer from '../app/appContainer';
import LandingDesktop from './landingDesktop';
import LandingMobile from './landingMobile';
import { useAppContext } from '../app/appContext';
const Landing: React.FC = () => {

    const [resources, setResources] = useState<ResourceList[]>();
    const {isphone} = useAppContext();

    const getResources = async () => {
        const response = await FetchResourceCategories();
        //console.log(`response in component: ${JSON.stringify(response)}`)
        if (Array.isArray(response)) {
            //console.log(`setting response items`)
            setResources(response);
        } else {
            console.error("Response is not an array");
        }
    };

    useEffect(() => {
        //console.log(`resources use effect ${JSON.stringify(resources)}`)
    }, [resources])

    useEffect(() => {
        getResources();
    }, [])

    return (
        <AppContainer>
            {resources ?
                !isphone ?
                    <LandingDesktop resources={resources} /> :
                    <LandingMobile resources={resources}/> :
                    <CircularProgress/>
                }
        </AppContainer>
    );
};

export default Landing;