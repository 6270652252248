import { Box, Typography } from "@mui/material";
import React from "react";
import { TosContainer, TosHeader } from "./shared";
import Terms, { Privacy } from "./TosTexts";

const TermsOfService: React.FC = () => {


    return (<TosContainer>
        <TosHeader>
            <Typography variant="h3">
                Terms of Service
            </Typography>
        </TosHeader>
        <Typography variant="body1">
            <Box>
                <Terms />
            </Box>
        </Typography>

        <TosHeader>
            <Typography variant="h3">
                Privacy Policy
            </Typography>
        </TosHeader>
        <Typography variant="body1">
            <Box>
                <Privacy />
            </Box>
        </Typography>

    </TosContainer>)
};

export default TermsOfService;