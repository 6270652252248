import React from 'react';
import { useAuth0, LogoutOptions } from '@auth0/auth0-react';
import { Button } from '@mui/material';

const LogoutButton: React.FC = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    console.log('clearing storage')
    sessionStorage.clear();
    logout({logoutParams: { returnTo: window.location.origin } });

  };

  return (
    <Button variant='contained' sx={{
        position: 'absolute',
        top: '10px',
        right: '40px',
        backgroundColor: '#FFDD57',
        color: 'black',
        zIndex: 1000,
        padding: 0,
      }}
      onClick={handleLogout}>
      Logout
      </Button>
  );
};


export default LogoutButton;