import axios, { AxiosRequestConfig } from 'axios';
import { handleApiError } from '../apiErrorHandler';
import { BaseUrl } from '../baseURL';
interface DeleteBookmarkParams {
  deleteId: string;
}

// API function to delete a bookmark
export async function deleteBookmark({ deleteId }: DeleteBookmarkParams): Promise<void> {
  const apiUrl = `${BaseUrl()}/bookmarks/${deleteId}`;
  const config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
    'authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
  },
};
  try {
    const response = await axios.delete(apiUrl, config);
    console.log('Bookmark deleted successfully:', response.data);
  } 
 catch (error: any) {
  throw handleApiError(error)
}
}


