// src/App.tsx
import React, { createContext, useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { theme } from './assets/theme';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AuthenticationGuard from './authentication-gaurd';
import GetStarted from './components/fre/getStarted';
import HomePage from './components/home';
import GetHelp from './components/fre/identityFactors';
import CollegeExperience from './components/fre/collegeProgress';
import FirstChat from './components/fre/firstChat';
import AppHome from './components/app/appHome';
import History from './components/history/history';
import Profile from './components/profile/profile';
import Resources from './components/resources/resources';
import LandingCheck from './components/fre/landingCheck';
import Landing from './components/landing/landing';
import { AppProvider } from './components/app/appContext';

const ProtectedRoutes = () => (
  <AppProvider>
    <Routes>
      <Route path="/*" element={<Landing />} />
      <Route path="/history" element={<History />} />
      <Route path="/get-started" element={<GetStarted />} />
      <Route path="/about-you/*" element={<GetHelp />} />
      <Route path="/about-you/first-chat" element={<FirstChat />} />
      <Route path="/about-you/progress" element={<CollegeExperience />} />
      <Route path="/chat" element={<AppHome key={location.key}/>} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/resources" element={<Resources />} />
      <Route path="/landing" element={<Landing />} />
      <Route path="/landing-check" element={<LandingCheck />} />
    </Routes>
  </AppProvider>
);

function App() {
  return (
      <ThemeProvider theme={theme}>
          <Routes>
            <Route path='/*' element={<HomePage></HomePage>} />
            <Route path="/*/*"  element={<AuthenticationGuard component={ProtectedRoutes} />} />
            <Route path="/app/*" element={<AuthenticationGuard component={ProtectedRoutes} />} />
          </Routes>
      </ThemeProvider>
  );
}

export default App;