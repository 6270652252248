import axios from 'axios';
import { BaseUrl } from "../baseURL";
import { School } from '../../model/nodeTypes';
import { handleApiError } from '../apiErrorHandler';


const fetchSchools = async ( setSchools: React.Dispatch<React.SetStateAction<School[]>>):Promise<School[]> => {
  const apiUrl = `${BaseUrl()}/schools`;
  console.log(`fetching schools`)
  try {

    const requestConfig = {
      url: apiUrl,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
    };
    const response = await axios(requestConfig);
    const school_list: School[] = response.data.school_list;
    const AnySchool: School= {school_name: 'Any'}
    school_list.push(AnySchool)
    console.log("got data from schools api: ", JSON.stringify(Array.from(new Set(school_list.map((option) => option.school_name)))))
    setSchools(school_list)
    // Return the choices or any other required data
    return school_list;
  } catch (error: any) {
   throw handleApiError(error)
}
}


export default fetchSchools;
