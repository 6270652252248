import React from 'react';
import { Box, Button, Drawer, Typography } from '@mui/material';
import { styled, useMediaQuery } from '@mui/system';


interface ResourceBoxProps {
    top?: string;
    left?: string;
    isphone?: boolean;
}

interface ResourceBgProps {
    bgimg: string;
}

export const BackgroundImageContainer = styled(Box)<{ url: string }>(({ url }) => ({
    backgroundImage: url,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',    // Cover the whole area
    backgroundPosition: 'center',   // Center the image
    height: '800px',
    width: "600px",
    position: 'relative',
}))

export const ResourceBox = styled(Box)<ResourceBoxProps> (({top, left, isphone=false}) => ({
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '10px 5px 5px rgba(0,0,0,.2)',
        borderRadius: '10px',
        border: '1px black',
        width: isphone? '250px': '200px',
        height: '230px',
        position: isphone? 'relative' :'absolute',
        top: isphone? '' : top,
        left: isphone? '': left,
}));

export const ResourceBgBox = styled(Box)<ResourceBgProps> (({bgimg}) => ({
    position: 'relative',
    width: '100%',
    height: '80px',
    borderRadius: '10px 10px 0 0',
    backgroundImage: bgimg
}));

export const ResourceLabel = styled(Typography)({
    width: '100%', 
    justifyContent: 'center', 
    position: 'absolute', 
    fontWeight: 500,
    bottom:'5px', 
    color: 'white'
})