import axios from 'axios';
import { BaseUrl } from "../baseURL";
import { handleApiError } from '../apiErrorHandler';
export interface Bookmarks {
    image_link?: string;
    page_url: string;
    page_friendly_name?: string;
    is_invalid?: boolean; 
    page_description?: string; 
}


const FetchResourcePages = async (): Promise<Bookmarks[] | null> => {
  const apiUrl = `${BaseUrl()}/bookmarks`;
  console.log(`fetching resources`);
  
  try {
    const requestConfig = {
      url: apiUrl,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
    };

    const response = await axios(requestConfig);

    if (response.status === 204) {
      console.log('No resources data available.');
      return null;
    }

    // Map the API response to the ResourcePagesItems interface
    const resources: Bookmarks[] = response.data.map((item: any) => ({
      image_link: item.image_link,
      page_url: item.page_url,
      page_friendly_name: item.page_friendly_name,
      page_description: item.page_description             
  }));

    if (resources && Array.isArray(resources)) {
      console.log(`Got response from resources API: ${JSON.stringify(resources)}`);
      return resources;
    } else {
      console.error('Unexpected response format:', response);
      throw new Error('Unexpected response format');
    }

  } catch (error: any) {
    throw handleApiError(error)
}
}

export default FetchResourcePages;