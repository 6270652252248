import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Handshake } from '@mui/icons-material'; // Import MUI icons
import { useMediaQuery } from '@mui/system';
import { ContinueButton, Root, Spacer, mediaSize, gridSize } from './shared';
import FreSteps from './base';
import { useNavigate } from 'react-router-dom';
import handshake from '../../assets/images/handshake.png';
import { ChooseSingleOption } from '../profile/attributeChoices';
import { AgeRange, GenderOptions, UserModel } from '../../model/nodeTypes';
import setProfile from '../../api/user/setProfile';
import { useAppContext } from '../app/appContext';
const CollegeExperience: React.FC = () => {
  const [age, setAge] = React.useState<string>('');
  const [gender, setGender] = React.useState<string>('');
  const navigate = useNavigate();
  const {isphone, profile, setProfile, setCallback} = useAppContext();
  const profileRef = React.useRef<UserModel | null>(profile);

  function navTo() {
    navigate('../about-you/first-chat');
  }

  useEffect(()=>{
    setCallback(() => navTo)
  },[])


  const updateProfile = async () => {
    try {
      if (age && gender) {
        profile && setProfile((prev) => {
          if (prev) {
            return {
              ...prev,
              age: age as AgeRange || '',
              gender: gender as GenderOptions || '',
              is_first_run: false,
            };
          }
          return prev;
        });
    }} catch (error) {
      console.error('Error sending school:', error);
    }
  };

  return (
    <Root>
      {isphone && <Handshake sx={{ fontSize: 40, color: 'white', marginTop: '50px' }} />}
      {!isphone && <img src={handshake} alt="handshake icon" style={{ maxWidth: '800px', width: '80%', height: 'auto' }} />}
      {!isphone && <FreSteps stage={'identity'} />}

      <Box sx={{
        padding: '20px',
        maxWidth: '500px',
      }}>
        <Typography variant={
          isphone ? "h6" : "h5"
        } color="white" gutterBottom>
          Almost done! Just a few more questions.
        </Typography>
        <div style={{ height: '20px' }}></div>
        <ChooseSingleOption selectedValue={age} options={Object.values(AgeRange) || []} setSelectedValue={setAge} label={"Age: "} />
        <ChooseSingleOption selectedValue={gender} options={Object.values(GenderOptions) || []} setSelectedValue={setGender} label={"Gender: "} />
      </Box>
      <Spacer />

      <ContinueButton variant="contained" disabled={!age || !gender} onClick={updateProfile}>
        Continue
      </ContinueButton>
      <Spacer />
    </Root>
  );
};


export default CollegeExperience;