import { Warning } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import "../../../../../assets/styles.css";
import { useAppContext } from "../../../../app/appContext";



interface ChatBotChatErrorProps {
  onRetry?: () => void;
}

const ChatBotChatError: FC<ChatBotChatErrorProps> = ({
  onRetry
}) => {
  const {errMessage} = useAppContext();
  return (

          <Box id="chatBotChatError">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="start"
              marginBottom={1}
            >
              <Warning
                aria-hidden="true"
                aria-label="Error icon"
                sx={{ color: "error.main", fontSize: 20}}
              />
              <Typography variant="body1" color="warning">
                &nbsp;{errMessage}
              </Typography>
            </Box>
            <Button variant="contained" color="primary" onClick={onRetry}>
              Retry
            </Button>
          </Box>
        )
};


export default ChatBotChatError;
